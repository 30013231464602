import React, { useState } from 'react';
import ProfileEntry from './ProfileEntry';
import { Radio } from 'antd';
import InputControls from './InputControls';
import { useCallback } from 'react';
import { capitalizeFirstChar } from '../Common/Utils';

const GenderRadioInput = ({ onChange, value }) => {

    return (
        <Radio.Group
            value={value}
            onChange={onChange}
        >
            <Radio value='male'>Male</Radio>
            <Radio value='female'>Female</Radio>
            <Radio value='other'>Other</Radio>
        </Radio.Group>
    );
};

const GenderInput = ({ user, editMode, onChangeEditMode }) => {

    const [gender, setGender] = useState("");

    const [isGenderEditable, setIsGenderEditable] = useState(false);

    const handleOnChange = useCallback((e) => {
        setGender(e.target.value);
    }, []);

    const handleEditableStatus = useCallback((status) => {
        setIsGenderEditable(status);
        onChangeEditMode(status);
    }, [onChangeEditMode]);

    const hasGender = Boolean(user.gender);
    const isEditable = isGenderEditable;

    return (
        <ProfileEntry
            hasBorder
            label="Gender:"
            value={
                (hasGender && !isEditable) ?
                    capitalizeFirstChar(user.gender) :
                    (isEditable) ?
                        <GenderRadioInput
                            onChange={handleOnChange}
                            value={gender}
                        />
                        :
                        '--'
            }
            controls={
                <InputControls
                    user={user}
                    inputName="gender"
                    inputValue={gender}
                    inputEditable={isGenderEditable}
                    changeEditableStatus={handleEditableStatus}
                    disableSave={!gender ? true : false}
                    disableEdit={editMode}
                />
            }
        />
    );
};

export default GenderInput;