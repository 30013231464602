import React, { Fragment, useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Form, Space, Divider, Spin, message, Modal } from 'antd';
import {
    BarsOutlined,
    CarryOutOutlined,
    FileTextOutlined,
    SettingOutlined,
    FileAddOutlined,
    RollbackOutlined
} from "@ant-design/icons";
import { SiHandshake } from 'react-icons/si'
import { BsCurrencyDollar } from "react-icons/bs"

import BasicInfo from './BasicInfo';
import CompletionsInfo from './CompletionsInfo';
import TasksUpload from './TasksUpload';
import ConfigInfo from './ConfigInfo';
import SkipAndRejectReasons from './SkipAndRejectReasons';
import Guidelines from './Guidelines';
import ConsentAgreement from './ConsentAgreement';
import UnitAndCost from './UnitAndCost/UnitAndCost';

import ErrorModal from '../../Error/ErrorModal';
import ErrorBoundary from '../../Error/ErrorBoundary';

import { PM_PROJECTS_PATH } from '../../../routes';
import { formatDateWithTimeStamp } from '../../../LSF/utils/date'

import PMPrjSingleContext from '../../../contexts/PMPrjSingleContext';

const { Item } = Form;

const SectionHeader = ({ text, icon }) => {
    return (
        <Fragment>
            <Divider />
            <h3 style={{ color: '#1890ff', marginBottom: '20px' }}><Space>{icon} {text}</Space></h3>
        </Fragment>
    );
}

const CreateProject = () => {

    const {
        createdProjectID,
        isLoading,
        isUploadingCsv,
        error,
        errorTasksUpload,

        createProject,
        uploadCsv,

        clearError,
        clearTasksUploadError,
        clearAllErrors,
    } = useContext(PMPrjSingleContext);

    const history = useHistory();
    const [tasksFile, setTasksFile] = useState(null);
    const [projectServiceValue, setprojectServiceValue] = useState({});

    useEffect(() => {
        // Clear all errors that may have been reported when the page unloads.
        return () => {
            clearAllErrors();
        }
        // eslint-disable-next-line
    }, [])


    const onFinish = async (values) => {
        //Transformation On the date values as they are reported as objects.
        //Changing the date vlaues to 00:00:00.00 GMT+0
        values.eta = formatDateWithTimeStamp(values.eta);
        values.startedOn = formatDateWithTimeStamp(values.startedOn);
        values.kickoffDate = formatDateWithTimeStamp(values.kickoffDate);
        values.status = 0;//Default value is active for the new project.
        values.name = values.name.trim();
        // The following is not needed as the feature of rich text editor has been delayed.
        // values.guidelines = `${values.guidelines.html}`; // extracting the html value from the guidelines value.

        // Adding the other option by default to the skip and rejection reasons.completionSkipReasons
        values.completionSkipReasons = Array.isArray(values.completionSkipReasons) ?
            [{ name: 'Other', requires_comment: true }, ...values.completionSkipReasons] :
            [{ name: 'Other', requires_comment: true }];
        values.completionRejectReasons = Array.isArray(values.completionRejectReasons) ?
            [{ name: 'Other', requires_comment: true }, ...values.completionRejectReasons] :
            [{ name: 'Other', requires_comment: true }];

        if (values.storageConfiguration)
            values.storageConfiguration = values.storageConfiguration.trim();

        if (values.locale === 'null')
            values.locale = null

        if (values.writingScript === 'Not Selected')
            values.writingScript = null

        let fileFormData = new FormData();
        fileFormData.append('tasks_file', tasksFile);

        if (values.agreement) {
            values.agreement = {
                content: values.agreement,
                agree: values.agree.trim(),
                disagree: values.disagree.trim()
            }
        }


        createProject(values)
            .then(newPrjID => uploadCsv(newPrjID, fileFormData, tasksFile.name))
            .then(newPrjID => {
                Modal.info({
                    title: 'Processing Tasks File Takes Time',
                    content: <div>
                        <p>Tasks file is uploaded successfully. However, it takes time to process the file.</p>
                        <p>Note: You will receive an email with the status when processing is done and if processing is successful, you will be able to see the completions in the completions table.</p>
                    </div>
                })
                history.push(`${PM_PROJECTS_PATH}/${newPrjID}`);
            })
            .catch(e => {
                //Do Nothing, errors will be handled by Modals according to their type.
            });
    }

    const onFinishFailed = (errors) => {
        message.error('Some project fields are invalid or empty, Please fix and try again', 3);
    }

    const onTasksUploadErrorDone = () => {
        clearTasksUploadError();
        history.push(`${PM_PROJECTS_PATH}/${createdProjectID}`);
    }


    return (
        <ErrorBoundary>
            <Spin spinning={isLoading || isUploadingCsv}>

                {/* Displaying the error message and hiding it if a new response is loaded.*/}
                <ErrorModal
                    error={error}
                    onDone={clearError}
                />

                {/* Modal to handle upload tasks failure */}
                <ErrorModal
                    error={errorTasksUpload}
                    moreInfo='Note: Although failed to upload tasks file, but the project is created successfully and you can try upload tasks file again from the project Edit/Display page which you will be redirected to now.'
                    onDone={onTasksUploadErrorDone}
                />

                <Form
                    onValuesChange={(changedValues, _) => {
                        if (changedValues.service)
                            setprojectServiceValue(changedValues.service)
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 9 }}
                    layout="horizontal"
                    scrollToFirstError={true}
                    style={{ marginTop: '40px' }}>

                    {/* The save and submit button */}
                    <Space
                        style={{
                            marginTop: '20px',
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                        <h1><FileAddOutlined /> Create New Project</h1>
                        <Item>
                            <Button type="primary" htmlType='submit'>Create Project</Button>
                        </Item>
                    </Space>

                    <SectionHeader text='Basic Information' icon={<BarsOutlined />} />
                    <ErrorBoundary><BasicInfo /></ErrorBoundary>

                    <SectionHeader text='Completions' icon={<CarryOutOutlined />} />
                    <ErrorBoundary><CompletionsInfo /></ErrorBoundary>
                    <ErrorBoundary><TasksUpload onFileSelected={setTasksFile} /></ErrorBoundary>


                    <SectionHeader text='Unit & Cost' icon={<BsCurrencyDollar />} />
                    <ErrorBoundary><UnitAndCost projectService={projectServiceValue} /></ErrorBoundary>


                    <SectionHeader text="Skip & Rejection Reasons" icon={<RollbackOutlined />} />
                    <ErrorBoundary><SkipAndRejectReasons /></ErrorBoundary>

                    <SectionHeader text='Guidelines' icon={<FileTextOutlined />} />
                    <ErrorBoundary><Guidelines /></ErrorBoundary>

                    <SectionHeader text='Participant Agreement' icon={<SiHandshake />} />
                    <ErrorBoundary><ConsentAgreement projectServiceValue={projectServiceValue} /></ErrorBoundary>

                    <SectionHeader text='Configuration' icon={<SettingOutlined />} />
                    <ErrorBoundary><ConfigInfo /></ErrorBoundary>

                </Form>
            </Spin>
        </ErrorBoundary>
    )
}

export default CreateProject;
