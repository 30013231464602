import { lxtBackend } from '../../../../../api/utils';

export const aiAssistedAPI = {
	getAIModels: async () => {
		const url = '/integrations';
		const resp = await lxtBackend.get(url);
		return resp.data;
	},

	validateModelConfiguration: async ({ modelId, typeId, configuration }) => {
		const url = '/validate-config';
		const formatRequestBody = () => {
			return {
				task_id: typeof modelId === 'string' ? Number(modelId) : modelId,
				type_id: typeof typeId === 'string' ? Number(typeId) : typeId,
				config:
					typeof configuration === 'string'
						? JSON.parse(configuration)
						: configuration,
			};
		};

		const formattedRequestBody = formatRequestBody();
		const resp = await lxtBackend.post(url, formattedRequestBody);
		return resp.data;
	},

	generatePredictions: async ({ projectId, payload }) => {
		const url = `pm/projects/${projectId}/predicitions`;

		const resp = await lxtBackend.post(url, payload);
		return resp.data;
	},

	checkGeneratePredictionsResult: async ({ queueTaskId }) => {
		const url = `/queue-tasks/${queueTaskId}/result`;
		const resp = await lxtBackend.get(url);

		return resp.data;
	},
};
