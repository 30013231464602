import React from "react";
import { observer } from "mobx-react";
import { Button, Modal, message } from "antd";
import {
  UndoOutlined,
  RedoOutlined,
  SettingOutlined,
  RollbackOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";

import styles from "./Panel.module.scss";
import Hint from "../Hint/Hint";

/**
 * Panel component with buttons:
 * Undo
 * Redo
 * Reset
 * Show Instructions
 * Settings
 */
export default observer(({ store }) => {
  const { history, original: isOriginalCompletion } = store.completionStore.selected;

  const handleReset = () => {
    // Asking the user to confirm the reset action.
    if (history.canUndo) {
      Modal.confirm({
        title: 'Are you sure you want to reset your work and the saved draft ?',
        icon: <ExclamationCircleOutlined />,
        onOk() {
          hanldeConfirmReset();
        }
      });
    } else { message.error("Can't reset empty values") }
  }
  const hanldeConfirmReset = () =>
  {
    history && history.reset();
    // This was added to enforce rendering of the completion after reset
    history.reinit();
  }

  return (
    <div className={styles.container + " ls-panel"}>
      <div className={`${styles.block} ${styles.block__controls}`}>
        <Button
          type="ghost"
          icon={<UndoOutlined />}
          style={{ display: "none" }}
          onClick={ev => {
            /**
             * Postpone using wavesuerferUndo to phase 2
             */
            //[1] is the first created history elem, rather than that it's empty [{of histores}]
            //const historyObjectKey = Object.keys(history.history[1])[0];
            //(historyObjectKey.includes('wave') ? history.wavesurferUndo() : history.undo());
            history && history.canUndo && history.undo();
            ev.preventDefault();
          }}
        >
          Undo
          {store.settings.enableHotkeys && store.settings.enableTooltips && <Hint>[ Ctrl+z ]</Hint>}
        </Button>
        <Button
          type="ghost"
          icon={<RedoOutlined />}
          style={{ display: "none" }}
          onClick={ev => {
            /**
             * Postpone using wavesuerferRedo to phase 2
             */
            //const historyObjectKey = Object.keys(history.history[1])[0];
            //(Object.keys(history.history[1])[0].includes('wave') ? history.wavesurferRedo() : history.redo());
            history && history.canRedo && history.redo();
            ev.preventDefault();
          }}
        >
          Redo
        </Button>
        <Button
          type="dashed"
          size='small'
          icon={<SettingOutlined />}
          onClick={ev => {
            store.toggleSettings();
            ev.preventDefault();
            return false;
          }}
        />
        <Button
          type="ghost"
          disabled={!isOriginalCompletion}
          size='small'
          icon={<RollbackOutlined />}
          onClick={ev => handleReset()}
        >
          Reset
        </Button>
        {store.setPrelabeling && (
          <Button
            style={{ display: "none" }}
            onClick={ev => {
              store.resetPrelabeling();
            }}
          >
            {" "}
            Reset Prelabeling
          </Button>
        )}
        {store.hasInterface("debug") && (
          <span>
            {history.undoIdx} / {history.history.length}
            {history.isFrozen && " (frozen)"}
          </span>
        )}
      </div>

      <div className={styles.block}>
        {store.description && store.showingDescription && (
          <Button
            type="primary"
            onClick={ev => {
              store.toggleDescription();
            }}
          >
            Hide Instructions
          </Button>
        )}
        {store.description && !store.showingDescription && (
          <Button
            type="primary"
            onClick={ev => {
              store.toggleDescription();
            }}
          >
            Show Instructions
          </Button>
        )}

        {/* <Button
          type="dashed"
          icon={<SettingOutlined />}
          onClick={ev => {
            store.toggleSettings();
            ev.preventDefault();
            return false;
          }}
        /> */}
        {/* <Button
          type="dashed"
          className="ls-fs"
          icon={store.settings.fullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
          onClick={ev => {
            store.settings.toggleFullscreen();
            ev.preventDefault();
            return false;
          }}
        /> */}
      </div>
    </div>
  );
});
