import React, { useState, useCallback } from 'react';
import ProfileEntry from './ProfileEntry';
import { DatePicker } from 'antd';
import InputControls from './InputControls';

const DateInput = ({ onChange, value }) => {
    const dateFormat = 'DD/MM/YYYY';

    return (
        <DatePicker
            allowClear={true}
            onChange={onChange}
            format={dateFormat}
            value={value}
        />
    );
};

const DateOfBirthInput = ({ user, editMode, onChangeEditMode }) => {

    const [dateOfBirth, setDateOfBirth] = useState(null);
    const [stringDateOfBirth, setStringDateOfBirth] = useState("");

    const [isDateOfBirthEditable, setIsDateOfBirthEditable,] = useState(false);

    const formatStringDate = useCallback((stringDate) => {
        return stringDate.split("-").reverse().join(" / ");
    }, []);

    const handleOnChange = useCallback((date, dateString) => {
        setDateOfBirth(date);
        setStringDateOfBirth(dateString);
    }, []);

    const handleEditableStatus = useCallback((status) => {
        setIsDateOfBirthEditable(status);
        onChangeEditMode(status);
    }, [onChangeEditMode]);

    const hasDateOfBirth = Boolean(user.dateOfBirth);
    const isEditable = isDateOfBirthEditable;

    return (
        <ProfileEntry
            hasBorder
            label="Date of Birth:"
            value={
                (hasDateOfBirth && !isEditable) ?
                    formatStringDate(user.dateOfBirth) :
                    (isEditable) ?
                        <DateInput
                            onChange={handleOnChange}
                            value={dateOfBirth}
                        />
                        :
                        '--'
            }
            controls={
                <InputControls
                    user={user}
                    inputName="dateOfBirth"
                    inputValue={stringDateOfBirth}
                    inputEditable={isDateOfBirthEditable}
                    changeEditableStatus={handleEditableStatus}
                    disableSave={!dateOfBirth ? true : false}
                    disableEdit={editMode}
                />
            }
        />
    );
};

export default DateOfBirthInput;