import { useState } from 'react';

export function usePredictionFormControl() {
	const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
	const [
		modelsConfigurationValidationStatus,
		setModelsConfigurationValidationStatus,
	] = useState({});

	const handleFormChange = (changedFields, allForms) => {
		const allFormsValues = Object.values(allForms?.forms).map((form) =>
			form.getFieldsValue(),
		);

		const isAnyToggleActive = allFormsValues.some((formValues) =>
			Object.entries(formValues).some(
				([key, value]) => key.endsWith('.isModelActive') && value === true,
			),
		);

		setIsSubmitEnabled(isAnyToggleActive);
	};

	const handleOnValidateModelConfiguration = (formName, isValid) => {
		setModelsConfigurationValidationStatus((prev) => ({
			...prev,
			[formName]: isValid,
		}));
	};

	const isAllValid = Object.values(modelsConfigurationValidationStatus).every(
		(status) => status === true,
	);

	return {
		isSubmitEnabled,
		isAllValid,
		handleFormChange,
		handleOnValidateModelConfiguration,
	};
}
