import { useQuery } from '@tanstack/react-query';
import { aiAssistedAPI } from '../api/aiAsissted';

const POLLING_INTERVAL_IN_SECONDS = 10;

function createQueryKey(params) {
	return ['models/checkGeneratePredictionsResult', params];
}

export function useCheckGeneratePredictionsResult({ queueTaskId }) {
	const result = useQuery({
		queryKey: createQueryKey(queueTaskId),
		queryFn: () =>
			aiAssistedAPI.checkGeneratePredictionsResult({ queueTaskId }),
		refetchOnWindowFocus: false,
		enabled: Boolean(queueTaskId),
		refetchInterval: (data) =>
			data?.statusCode === 5 ? false : POLLING_INTERVAL_IN_SECONDS * 1000,
	});

	return result;
}
