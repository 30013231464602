import { useState } from 'react';
import { Form, Button } from 'antd';
import styled from 'styled-components';

import { usePMProjectSingleContext } from '../../../../contexts/PMPrjSingleContext';
import { formatError } from '../../../../contexts/ResponseErrorFormatter';

import ErrorModal from '../../../Error/ErrorModal';

import { PredictionResultAlert } from './components/PredictionResultAlert';
import { ModelConfigurationForm } from './components/ModelConfigurationForm';
import { useGeneratePredictions } from './mutatuion/useGeneratePredictions';
import { useCheckGeneratePredictionsResult } from './query/useCheckGeneratePredictionsResult';
import { usePredictionFormControl } from './hooks/usePredictionFormControl';

const GridLayout = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-row-gap: 4rem;
`;

function AiAssisted() {
	const { selectedProject } = usePMProjectSingleContext();

	const {
		isSubmitEnabled,
		isAllValid,
		handleFormChange,
		handleOnValidateModelConfiguration,
	} = usePredictionFormControl();

	const [showErrorModal, setShowErrorModal] = useState(false);

	const showModal = () => setShowErrorModal(true);
	const handleCancel = () => setShowErrorModal(false);

	const {
		generatePredictions,
		backgroundTaskID,
		generatePredictionsError,
		isGeneratePredictionsError,
	} = useGeneratePredictions({
		onError: showModal,
	});

	const { data } = useCheckGeneratePredictionsResult({
		queueTaskId: backgroundTaskID,
	});

	const handleOnFinish = (name, { forms }) => {
		const chosenModels = Object.values(forms).map((formAttributes) => {
			const formData = formAttributes.getFieldsValue();
			return {
				id: formData.modelId,
				model_options: JSON.parse(formData.modelConfiguration),
			};
		});

		generatePredictions({
			payload: { models: chosenModels },
			projectId: selectedProject.id,
		});
	};

	const isGenerateEnabled = isSubmitEnabled && isAllValid;

	return (
		<>
			{data?.statusCode && (
				<PredictionResultAlert statusCode={data?.statusCode} />
			)}

			<Form.Provider
				onFormFinish={handleOnFinish}
				onFormChange={handleFormChange}
			>
				{isGeneratePredictionsError && (
					<ErrorModal
						error={formatError(
							generatePredictionsError,
							'Failed To Generate Predictions!',
						)}
						onDone={handleCancel}
						visible={showErrorModal}
					/>
				)}

				<Form>
					<Form.Item style={{ textAlign: 'right' }}>
						<Button
							type="primary"
							htmlType="submit"
							disabled={!isGenerateEnabled}
						>
							Generate
						</Button>
					</Form.Item>

					<GridLayout>
						<ModelConfigurationForm
							label="Speech To Text"
							onValidateConfiguration={handleOnValidateModelConfiguration}
						/>
						<ModelConfigurationForm
							label="Speaker Diarization"
							onValidateConfiguration={handleOnValidateModelConfiguration}
						/>
					</GridLayout>
				</Form>
			</Form.Provider>
		</>
	);
}

export { AiAssisted };
