import AdhocForm from "./AdhocForm";
import AdhocTable from "./AdhocTable";

const Adhoc = () => {
    return (
        <>
            <AdhocForm />
            <AdhocTable />
        </>
    )
}

export default Adhoc;