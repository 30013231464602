import React, { Fragment, useEffect, useState, useMemo, useCallback } from 'react';
import { Table, Button, Pagination, Space, Switch } from 'antd';
import { CloseOutlined, CheckOutlined, LinkOutlined } from "@ant-design/icons";
import isEqual from 'lodash.isequal';

import { renderTagsTableCell } from '../../../Common/TagsTableCell';
import ContributorsTableControls from './ContributorsTableControls';
import ErrorPage from '../../../Error/ErrorPage';
import ErrorModal from '../../../Error/ErrorModal';
import { TrueIcon, FalseIcon, AssignedIcon, NotAssignedIcon } from '../../../Common/CustomIcons';

import useOptionsContext from '../../../../contexts/OptionsContext';
import { usePMProjectSingleContext, CONTRIBUTORS_ASSIGNMENT_TYPE } from '../../../../contexts/PMPrjSingleContext';
import '../../../Styles/Button.scss'
import { EMPTY_TABLE_CELL } from '../../../../Constants';
import { CustomLink } from '../../../Common/CustomLink';

const EMPTY_FILTERS_INFO = {
    isActive: null,
    signedLatestServiceAgreement: null,
    assignedDP: null,
    assignedRV: null,
    assignedAH: null,
    otherLanguages: null,
    nativeLanguage: null,
    skills: null,
}

const TABLE_EMPTY_STATE = {
    filtersInfo: EMPTY_FILTERS_INFO,
    sorterInfo: {},
    selectedContributorIDs: [],
};


const adHocColumn = ({ handleSingleContributorAssign, tableState, state, setState }) => ({
    title: "Ad-Hoc",
    dataIndex: "assignedAH",
    key: "assignedAH",
    width: '200px',

    filteredValue: tableState.filtersInfo.assignedAH || null,
    filterMultiple: false,
    filters: [
        {
            text: 'Assigned',
            value: true,
        },
        {
            text: 'Not Assigned',
            value: false,
        }
    ],

    onCell: (record) => ({
        onMouseEnter: () => { setState(ps => ({ ...ps, hoveredAdhocCellRecordID: record.id })) }, // mouse enter row
        onMouseLeave: () => { setState(ps => ({ ...ps, hoveredAdhocCellRecordID: null })) }, // mouse leave row
    }),
    render: (_, record) =>
        <HoverableAssignmentTableCell
            isHovered={state.hoveredAdhocCellRecordID === record.id}
            isAssigned={record.assignedAH}
            isActive={record.isActive}
            onButtonClick={() => handleSingleContributorAssign(record.id, !record.assignedAH, CONTRIBUTORS_ASSIGNMENT_TYPE.AdHoc)}
        />
    ,
})

const factoryContributorsTableColumns = ({ state, setState, tableState, handleSingleContributorAssign, allLanguages, skills }) => [
    {
        title: "ID",
        dataIndex: "id",
        key: "id",
        sorter: () => undefined, // Since the sorting takes place in the backend.
        sortOrder: tableState.sorterInfo.columnKey === 'id' && tableState.sorterInfo.order,
        sortDirections: ['ascend', 'descend'],
        render: (id) => {
            return <CustomLink to={`app/contribute/profile/${id}`}>{id} <LinkOutlined /></CustomLink>
        },
    },
    {
        title: "First Name",
        dataIndex: "firstName",
        key: "firstName",
        sorter: () => undefined, // Since the sorting takes place in the backend.
        sortOrder: tableState.sorterInfo.columnKey === 'firstName' && tableState.sorterInfo.order,
        sortDirections: ['ascend', 'descend'],
        render: (firstName) => firstName || EMPTY_TABLE_CELL,
    },
    {
        title: "Last Name",
        dataIndex: "lastName",
        key: "lastName",
        sorter: () => undefined, // Since the sorting takes place in the backend.
        sortOrder: tableState.sorterInfo.columnKey === 'lastName' && tableState.sorterInfo.order,
        sortDirections: ['ascend', 'descend'],
        render: (lastName) => lastName || EMPTY_TABLE_CELL,
    },
    {
        title: "Email",
        dataIndex: "email",
        key: "email",
        // sorter: (recA, recB) => sortCompare(recA.email, recB.email),
        sorter: () => undefined, // Since the sorting takes place in the backend.
        sortOrder: tableState.sorterInfo.columnKey === 'email' && tableState.sorterInfo.order,
        sortDirections: ['ascend', 'descend']
    },
    {
        title: "Data Processor",
        dataIndex: "assignedDP",
        key: "assignedDP",
        width: '200px',

        filteredValue: tableState.filtersInfo.assignedDP || null,
        filterMultiple: false,
        filters: [
            {
                text: 'Assigned',
                value: true,
            },
            {
                text: 'Not Assigned',
                value: false,
            }
        ],

        onCell: (record) => ({
            onMouseEnter: () => { setState(ps => ({ ...ps, hoveredDataProcessorCellRecordID: record.id })) }, // mouse enter row
            onMouseLeave: () => { setState(ps => ({ ...ps, hoveredDataProcessorCellRecordID: null })) }, // mouse leave row
        }),
        render: (_, record) =>
            <HoverableAssignmentTableCell
                isHovered={state.hoveredDataProcessorCellRecordID === record.id}
                isAssigned={record.assignedDP}
                isActive={record.isActive}
                onButtonClick={() => handleSingleContributorAssign(record.id, !record.assignedDP, CONTRIBUTORS_ASSIGNMENT_TYPE.DataProcessor)}
            />,
    },
    {
        title: "Reviewer",
        dataIndex: "assignedRV",
        key: "assignedRV",
        width: '200px',

        filteredValue: tableState.filtersInfo.assignedRV || null,
        filterMultiple: false,
        filters: [
            {
                text: 'Assigned',
                value: true,
            },
            {
                text: 'Not Assigned',
                value: false,
            }
        ],

        onCell: (record) => ({
            onMouseEnter: () => { setState(ps => ({ ...ps, hoveredReviewerCellRecordID: record.id })) }, // mouse enter row
            onMouseLeave: () => { setState(ps => ({ ...ps, hoveredReviewerCellRecordID: null })) }, // mouse leave row
        }),
        render: (_, record) =>
            <HoverableAssignmentTableCell
                isHovered={state.hoveredReviewerCellRecordID === record.id}
                isAssigned={record.assignedRV}
                isActive={record.isActive}
                onButtonClick={() => handleSingleContributorAssign(record.id, !record.assignedRV, CONTRIBUTORS_ASSIGNMENT_TYPE.Reviewer)}
            />,

    },
        adHocColumn({ handleSingleContributorAssign, tableState, state, setState }),
    {
        title: "Active",
        dataIndex: "isActive",
        key: "isActive",
        render: (text, record) => record.isActive ? <TrueIcon iconSize='1.5rem' /> : <FalseIcon iconSize='1.5rem' />,

        filteredValue: tableState.filtersInfo.isActive || null,
        filterMultiple: false,
        filters: [
            {
                text: 'Active',
                value: true,
            },
            {
                text: 'Inactive',
                value: false,
            }
        ],

        sorter: () => undefined, // Since the sorting takes place in the backend.
        sortOrder: tableState.sorterInfo.columnKey === 'isActive' && tableState.sorterInfo.order,
        sortDirections: ['ascend', 'descend']
    },
    {
        title: "Latest MSA Status",
        dataIndex: "signedLatestAgreements",
        key: "signedLatestServiceAgreement",
        render: (text, record) => record.signedLatestAgreements ? <TrueIcon iconSize='1.5rem' /> : <FalseIcon iconSize='1.5rem' />,

        filteredValue: tableState.filtersInfo.signedLatestServiceAgreement || null,
        filterMultiple: false,
        filters: [
            {
                text: 'Accepted',
                value: true,
            },
            {
                text: 'Rejected',
                value: false,
            }
        ],
    },
    {
        title: "Skills",
        key: "skills",
        dataIndex: 'skills',
        render: renderTagsTableCell,
        filters: skills.map(skill => ({ text: skill.name, value: skill.id })),
        filteredValue: tableState.filtersInfo.skills || null,
        filterMultiple: true,
        filterSearch: true,
    },
    {
        title: "Other Languages",
        key: "otherLanguages",
        dataIndex: 'otherLanguages',
        render: renderTagsTableCell,
        filters: allLanguages,
        filteredValue: tableState.filtersInfo.otherLanguages || null,
        filterMultiple: true,
        filterSearch: true,
    },
    {
        title: "Native Language",
        key: "nativeLanguage",
        dataIndex: 'nativeLanguage',
        render: renderTagsTableCell,
        filters: allLanguages,
        filteredValue: tableState.filtersInfo.nativeLanguage || null,
        filterMultiple: true,
        filterSearch: true,
    }
].filter(Boolean);


const PaginationComponent = ({ count, limit, skip, onChange }) => (
    <Pagination
        showSizeChanger
        showQuickJumper
        showTotal={() => `Total: ${count}`}
        total={count}
        pageSize={limit}
        current={(skip / limit) + 1}
        onChange={onChange}
    />
);

const HoverableAssignmentTableCell = ({ isHovered, isAssigned, isActive, onButtonClick }) => {
    return (
        <Space align='start'>

            {/* Displaying the assignment state. */}
            {isAssigned ? <AssignedIcon /> : <NotAssignedIcon />}

            {/* Displaying the button when hovered user is active or assigned */}
            {isHovered && (isActive || isAssigned) &&
                <Button
                    type={isAssigned ? 'danger' : 'primary'}
                    className={isAssigned ? '' : 'btn-success'}
                    size='small'
                    shape='round'
                    style={{ fontSize: '0.8rem' }}
                    icon={isAssigned ? <CloseOutlined /> : <CheckOutlined />}
                    onClick={onButtonClick}>
                    {isAssigned ? 'Unassign' : 'Assign'}
                </Button>
            }

            {/* Displaying user inactive text when the user is neither active nor assigned */}
            {isHovered && !(isActive || isAssigned) &&
                <span>Inactive User</span>
            }
        </Space>
    )
}

const ContributorsTable = () => {

    const {
        contributors,
        contributorsCount,
        contributorsLoading,
        contributorsCriteria,
        contributorsFetchError,

        fetchContributors,
        setContributorsAssignedState,
    } = usePMProjectSingleContext();

    const {
        isLoading: areOptionsLoading,

        skills,
        skillsError,
        fetchSkillOptions,
        clearSkillsError,

        languages,
        languagesError,
        fetchLanguageOptions,
        clearLanguagesError,
    } = useOptionsContext();

    // TODO: name this state
    const [state, setState] = useState({
        showPasteAssignModal: false,
        hoveredReviewerCellRecordID: null,
        hoveredDataProcessorCellRecordID: null,
        hoveredAdhocCellRecordID: null,
    });

    const [tableState, setTableState] = useState(TABLE_EMPTY_STATE);

    useEffect(() => {
        fetchSkillOptions();
        fetchLanguageOptions();
        fetchContributors();

        //eslint-disable-next-line
    }, []);

    const allLanguages = useMemo(() =>
        languages.map(ln => ({ text: ln.name, value: ln.id }))
        , [languages])

    const handleShowOnlyAssignedChange = value => {
        fetchContributors({
            ...contributorsCriteria,
            skip: 0,
            showOnlyAssigned: value,
        })
    }
    const clearTableState = () => {
        setTableState(TABLE_EMPTY_STATE);
    }

    const isTableRecordSelectable = (record) => Boolean(record.isActive)

    const handleSingleContributorAssign = useCallback((ctid, assign, assignmentType) => {
        setContributorsAssignedState([ctid], assign, assignmentType)
            .then(res => fetchContributors(contributorsCriteria)); // TODO display failed assignments.
    }, [contributorsCriteria, setContributorsAssignedState, fetchContributors])

    const handleTableFiltersAndSorterChange = (newFilters, newSorter) => {

        const filtersChanged = !isEqual(newFilters, tableState.filtersInfo);



        fetchContributors({
            ...contributorsCriteria,

            skip: filtersChanged ? 0 : contributorsCriteria.skip,
            assignedDP: Array.isArray(newFilters.assignedDP) && newFilters.assignedDP.length > 0 ? newFilters.assignedDP[0] : null,
            assignedRV: Array.isArray(newFilters.assignedRV) && newFilters.assignedRV.length > 0 ? newFilters.assignedRV[0] : null,
            assignedAH: Array.isArray(newFilters.assignedAH) && newFilters.assignedAH.length > 0 ? newFilters.assignedAH[0] : null,
            isActive: Array.isArray(newFilters.isActive) && newFilters.isActive.length > 0 ? newFilters.isActive[0] : null,
            signedLatestServiceAgreement: Array.isArray(newFilters.signedLatestServiceAgreement) && newFilters.signedLatestServiceAgreement.length > 0 ? newFilters.signedLatestServiceAgreement[0] : null,
            otherLanguages: newFilters.otherLanguages,
            nativeLanguage: newFilters.nativeLanguage,
            skills: newFilters.skills,

            orderColumn: newSorter.order ? newSorter.columnKey : null, // newSorter.order is used to know whether to the sort is applied or not as the columnKey is not updated when the order is removed.
            orderAscending: newSorter.order ? newSorter.order === 'ascend' : true,
        });

        // Updating the table filter object.
        setTableState(ps => ({
            ...ps,
            filtersInfo: newFilters,
            sorterInfo: newSorter,
        }));
    }
    const handleTablePaginationChange = (pageNumber, pageSize) => {
        let newSkip = (pageNumber - 1) * pageSize;
        let newLimit = pageSize;
        // Fetching user with the new skip and limit.
        fetchContributors({
            ...contributorsCriteria,
            skip: newSkip,
            limit: newLimit,
        });
    }
    const handleTableRowSelection = (isSelected, row) => {
        setTableState(ps => ({
            ...ps,
            selectedContributorIDs: isSelected ? [...tableState.selectedContributorIDs, row.id] : tableState.selectedContributorIDs.filter(suid => suid !== row.id),
        }));
    }
    const handleTableSelectDeselectAll = (isSelected) => {
        const currentCTids = contributors.filter(u => isTableRecordSelectable(u)).map(u => u.id);
        setTableState(ps => ({
            ...ps,
            selectedContributorIDs: isSelected ?

                // Adding the current contributor ID's and removing any duplicates as individual rows could be selected before this action.
                [...new Set([...tableState.selectedContributorIDs, ...currentCTids])] :

                // Removing the current contributor ID's from the selected id's.
                tableState.selectedContributorIDs.filter(uid => !currentCTids.includes(uid)),
        }));
    }

    const handleTableRowClick = (record) => {
        if (record && isTableRecordSelectable(record)) {
            const shouldBeSelected = !tableState.selectedContributorIDs.includes(record.id);
            handleTableRowSelection(shouldBeSelected, record);
        }
    }

    const columns = useMemo(() => {

        return factoryContributorsTableColumns({  state, setState, tableState, handleSingleContributorAssign, allLanguages, skills })
    }, [state, setState, tableState, handleSingleContributorAssign, allLanguages, skills])

    return (
        <Fragment>

            <Space style={{ marginBottom: '40px' }}>
                <Switch
                    checkedChildren={<CheckOutlined />}
                    defaultChecked={contributorsCriteria.showOnlyAssigned}
                    onChange={handleShowOnlyAssignedChange}
                />
                <span>Show Only Assigned Contributors</span>
            </Space>

            {/* The header controls of the table */}
            <ContributorsTableControls
                selectedContributorIDs={tableState.selectedContributorIDs}
                clearTableState={clearTableState}
            />

            {/* The error display page. */}
            <ErrorPage loading={contributorsLoading} error={contributorsFetchError} />

            <ErrorModal error={languagesError} onDone={clearLanguagesError} />

            <ErrorModal error={skillsError} onDone={clearSkillsError} />

            {
                !contributorsFetchError &&
                <Space direction='vertical' style={{ width: '100%' }}>
                    <Table
                        size='small'
                        scroll={{ x: true }}
                        dataSource={contributors ? contributors.map(item => ({ ...item, key: item.id })) : []}
                        columns={columns}
                        loading={contributorsLoading || areOptionsLoading}
                        onChange={(pagination, filters, sorter) => handleTableFiltersAndSorterChange(filters, sorter)}
                        pagination={false}
                        onRow={(record) => ({
                            onDoubleClick: () => { handleTableRowClick(record) },
                        })}
                        rowSelection={{
                            selectedRowKeys: tableState.selectedContributorIDs,
                            onSelect: (row, isSelected) => handleTableRowSelection(isSelected, row),
                            onSelectAll: (isSelected) => handleTableSelectDeselectAll(isSelected),
                            getCheckboxProps: record => ({
                                disabled: !isTableRecordSelectable(record),
                            }),
                        }}
                    />
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <PaginationComponent
                            count={contributorsCount}
                            skip={contributorsCriteria.skip}
                            limit={contributorsCriteria.limit}
                            onChange={handleTablePaginationChange}
                        />
                    </div>
                </Space>
            }

        </Fragment>
    )
}

export default ContributorsTable;
