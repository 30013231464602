import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { useParams, useHistory, useLocation, Link } from 'react-router-dom';
import { Form, Space, Spin, Result, Button } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';

import Content from '../AntdWrappers/Content/Content';
import ErrorAlert from '../Error/ErrorAlert';
import useOptionsContext, { OptionsContextProvider } from '../../contexts/OptionsContext';
import { useAuthContext } from '../../contexts/AuthContext';
import { LOGIN_PATH, ROOT_PATH } from '../../routes';
import { OTHER_EDUCATION_LEVEL_ID } from '../../Constants';

import "./Auth.scss"

import FirstName from './RegisterationFormItems/FirstName';
import LastName from './RegisterationFormItems/LastName';
import Password from './RegisterationFormItems/Password';
import ConfirmPassword from './RegisterationFormItems/ConfirmPassword';
import Skills from './RegisterationFormItems/Skills';
import NativeLanguage from './RegisterationFormItems/NativeLanguage';
import OtherLanguages from './RegisterationFormItems/OtherLanguages';
import CurrentCity from './RegisterationFormItems/CurrentCity';
import EducationLevel from './RegisterationFormItems/EducationLevel';




const { Item } = Form

// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const RegistrationMobile = (props) => {
    return (
        <OptionsContextProvider>
            <RegistrationInternal {...props} />
        </OptionsContextProvider>
    )
}

const RegistrationInternal = ({ location }) => {

    const {
        user,
        error,
        isLoading: authLoading,
        registerInvitedUser,
        refreshXsrfToken,
        isLxtUser,
    } = useAuthContext();

    const {
        isLoading: optionsLoading,
        fetchLanguageOptions,
        fetchSkillOptions
    } = useOptionsContext();

    const [skills, setSkills] = useState([]);
    const [otherLanguages, setOtherLanguages] = useState([]);
    const [nativeLanguage, setNativeLanguage] = useState(undefined)
    const [hasNativeLangOnly, setHasNativeLangOnly] = useState(false)
    const [educationLevelId, setEducationLevelId] = useState(undefined);
    const [otherEducation, setOtherEducation] = useState('');

    const { token } = useParams();
    const history = useHistory();
    const query = useQuery();

    useEffect(() => {
        refreshXsrfToken(true)
            .then(() => {
                fetchLanguageOptions(token);
                fetchSkillOptions(token);
            })
        //eslint-disable-next-line
    }, [])

    const handleOnSkillsChange = useCallback((skill) => {
        setSkills(skill)
    }, [setSkills])

    const handleOnLanguageChange = useCallback((language) => {
        setOtherLanguages(language)
    }, [setOtherLanguages])

    const handleOnNativeLanguageChange = useCallback((language) => {
        setNativeLanguage(language)
    }, [setNativeLanguage])

    const handleOnToggleCheckBox = useCallback((e) => {
        setHasNativeLangOnly(e.target.checked)
        if (e.target.checked === true)
            setOtherLanguages([])

    }, [setHasNativeLangOnly]);

    const handleOnEducationLevelChange = useCallback((educationLevel) => {
        setEducationLevelId(educationLevel)
    }, [setEducationLevelId]);

    const handleOnOtherEducationChange = useCallback((e) => {
        setOtherEducation(e.target.value)
    }, [setOtherEducation])

    const handleOnFinish = useCallback((values) => {
        if (token && query.get('email')) {

            const currentCityValue = values.currentLocation.properties;

            const selectedCurrentCity = {
                city: currentCityValue?.city || currentCityValue?.state,
                state: currentCityValue.state,
                country: currentCityValue.country,
                lat: currentCityValue.lat,
                lon: currentCityValue.lon,
                formatted: currentCityValue.formatted,
            };

            const userData = {
                ...values,
                firstName: values.firstName.trim(),
                lastName: values.lastName.trim(),
                currentLocation: selectedCurrentCity,
                skills,
                otherLanguages,
                nativeLanguage,
                email: query.get('email')
            };

            const hasOtherEducation = Boolean(educationLevelId === OTHER_EDUCATION_LEVEL_ID);
            if (hasOtherEducation)
            {
                userData.otherEducation = otherEducation;
            } else
            {
                userData.educationId = educationLevelId;
            }


            registerInvitedUser(token, userData)
                .then(() => {
                    //Redirecting the user to the root after he creates his/her account successfully
                    history.push(ROOT_PATH);
                })
        }
    }, [token, query, skills, otherLanguages, nativeLanguage, educationLevelId, registerInvitedUser, otherEducation, history])

    return (
        <Fragment>

            {/* Displaying an error message if the user is already logged */}
            {user &&
                <Result
                    status="403"
                    title="Access Not Allowed"
                />
            }

            {/* Create Account Form */}
            {!user &&
                <Content alignVertically >
                    <Spin spinning={authLoading || optionsLoading}>
                        <Space align="center" direction="vertical">

                            <Form
                                labelCol={{ span: 5 }}
                                wrapperCol={{ span: 15 }}
                                style={{ width: '80vw' }}
                                onFinish={handleOnFinish}
                            >
                                <FirstName />

                                <LastName />

                                {!isLxtUser(query.get('email')) &&
                                    <Fragment>
                                        <Password />
                                        <ConfirmPassword />
                                    </Fragment>
                                }

                                <Skills onSkillChange={handleOnSkillsChange} selectedSkills={skills} />


                                <NativeLanguage
                                    onLanguageChange={handleOnNativeLanguageChange}
                                    otherLanguages={otherLanguages}
                                    nativeLanguage={nativeLanguage}
                                />

                                <OtherLanguages
                                    onLanguageChange={handleOnLanguageChange}
                                    nativeLanguage={nativeLanguage}
                                    otherLanguages={otherLanguages}
                                    OnToggleCheckBox={handleOnToggleCheckBox}
                                    isDisabled={hasNativeLangOnly}
                                />

                                <CurrentCity />

                                <EducationLevel
                                    onEducationLevelChange={handleOnEducationLevelChange}
                                    educationLevel={educationLevelId}
                                    otherEducation={otherEducation}
                                    onOtherEducationChange={handleOnOtherEducationChange}
                                />

                                {/* Sign in and Submit buttons */}
                                <div>
                                    <Item>
                                        <Button type="primary" htmlType="submit" style={{ width: '80vw' }}>
                                            <UserAddOutlined /> Create Account
                                        </Button>
                                    </Item>
                                </div>
                            </Form>

                            <div className='registeration-mobile-footer'>
                                <p>Already have an account? </p>
                                <Link to={LOGIN_PATH}>Sign In</Link>
                            </div>

                            <ErrorAlert error={error} />

                        </Space>
                    </Spin>
                </Content>
            }

        </Fragment>
    )
}

export default RegistrationMobile
