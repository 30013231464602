import React, {
	Fragment,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Button,
	Checkbox,
	Col,
	Collapse,
	Divider,
	Dropdown,
	Input,
	Menu,
	message,
	Modal,
	Pagination,
	Row,
	Space,
	Switch,
	Table,
	Upload,
} from 'antd';

import {
	CaretRightOutlined,
	CheckOutlined,
	DownloadOutlined,
	DownOutlined,
	LoadingOutlined,
	RocketOutlined,
	SearchOutlined,
	SyncOutlined,
	UploadOutlined,
	UsergroupAddOutlined,
} from '@ant-design/icons';

import ErrorModal from '../../../Error/ErrorModal';
import ErrorPage from '../../../Error/ErrorPage';
import { formatError } from '../../../../contexts/ResponseErrorFormatter';

import FieldLabel from '../../../Common/FieldLabel';
import TagsBox from '../../../Common/TagsBox';
import ResetTableButton from '../../../Common/ResetTableButton';
import RefreshTableButton from '../../../Common/RefreshTableButton';
import { formatDate } from '../../../Common/Utils';
import InfoModal from '../../../Common/InfoModal';
import CompletionUpdate from './CompletionUpdate';

import {
	COMPLETION_STATUS_VALUES,
	DOWNLOAD_AUDIO_FILES_PRJECT_STATUS,
	EMPTY_TABLE_CELL,
	ROLE_DATA_PROCESSOR,
	ROLE_DATA_REVIEWER,
	SPECIAL_TAGS,
} from '../../../../Constants';

import snakeCase from 'lodash.snakecase';
import isEqual from 'lodash.isequal';
import { isEmpty } from 'lodash-es';

import PMPrjSingleContext from '../../../../contexts/PMPrjSingleContext';
import usePMCompletionsContext from '../../../../contexts/PMCompletionsContext';
import useSocketContext from '../../../../contexts/SocketContext';
import {
	initDowloadProjectFilesSession,
	setShowDownloadCenterRequest,
} from '../../../../store/downloadCenterSlice';
import { useUnitAndCostApprove } from '../../../../api/mutations/useUnitAndCostMutations';

import './Completions.scss';
import { GridView } from './GridView';

const { Panel } = Collapse;
const labelColSpan = 4;
const fieldColSpan = 16;
const rowStyle = { marginBottom: '20px', marginTop: '20px' };
const girdMaxRowCount = 10000;

const SearchHeader = () => {
    return (
        <span style={{ fontSize: '1.1rem' }} >Search <SearchOutlined /></span>
    )
}
const ManualAssignmentHeader = () => {
    return (
        <span style={{ fontSize: '1.1rem' }} >Manual Assignment <UsergroupAddOutlined /></span>
    )
}

const EMPTY_FILTERS_INFO = {
    status: null,
}

const getCompletionTableColumnsConfig = ({ completionTableState, onApprove }) => {
    return {
        completionId: {
            title: "Completion ID",
            dataIndex: "id",
            key: "id",
            sorter: () => undefined, // Since the sorting takes place in the backend.
            sortOrder: completionTableState.sorterInfo.columnKey === 'id' && completionTableState.sorterInfo.order,
            sortDirections: ['ascend', 'descend'],
        },
        taskId: {
            title: "Task ID",
            dataIndex: "taskId",
            key: "taskId",
            sorter: () => undefined, // Since the sorting takes place in the backend.
            sortOrder: completionTableState.sorterInfo.columnKey === 'taskId' && completionTableState.sorterInfo.order,
            sortDirections: ['ascend', 'descend'],
        },
        metaData: {
            title: "Audio Length",
            dataIndex: "metadata",
            key: "metadata",
            align: 'center',
            render: (text, record) => {
                if (record.metadata) {
                    const audioMeta = Object.values(record.metadata)
                        .filter(val => val.length)
                        .map(val => val.length)
                        .join(', ');
                    return audioMeta ? audioMeta : EMPTY_TABLE_CELL;
                }
                else {
                    return EMPTY_TABLE_CELL;
                }
            },
            sorter: () => undefined, // Since the sorting takes place in the backend.
            sortOrder: completionTableState.sorterInfo.columnKey === 'metadata' && completionTableState.sorterInfo.order,
            sortDirections: ['ascend', 'descend'],
        },
        status: {
            title: "Status",
            key: "status",
            render: (text, record) => COMPLETION_STATUS_VALUES[record.status],
            filters: Object.keys(COMPLETION_STATUS_VALUES).map(st => ({ text: COMPLETION_STATUS_VALUES[st], value: st })),
            filteredValue: completionTableState.filtersInfo.status || null,
            filterMultiple: true,
            sorter: () => undefined, // Since the sorting takes place in the backend.
            sortOrder: completionTableState.sorterInfo.columnKey === 'status' && completionTableState.sorterInfo.order,
            sortDirections: ['ascend', 'descend'],
        },
        dataprocessorId: {
            title: "Data Processor ID",
            key: "dataProcessor",
            render: (text, record) => record.dataProcessor ? record.dataProcessor.id : EMPTY_TABLE_CELL,
            sorter: () => undefined, // Since the sorting takes place in the backend.
            sortOrder: completionTableState.sorterInfo.columnKey === 'dataProcessor' && completionTableState.sorterInfo.order,
            sortDirections: ['ascend', 'descend'],
        },
        dataprocessorName: {
            title: "Data Processor",
            key: "dpFullName",
            render: (text, record) => {

                const firstName = record?.dataProcessor?.firstName
                const lastName = record?.dataProcessor?.lastName
                const fullName = `${firstName} ${lastName}`

                return record.dataProcessor ? fullName : EMPTY_TABLE_CELL
            },
        },
        dataprocessorEmail: {
            title: "Data Processor Email",
            key: "dpEmail",
            render: (text, record) => (record.dataProcessor && record.dataProcessor.email) ? record.dataProcessor.email : EMPTY_TABLE_CELL,
        },
        dataprocessorHandleTime: {
            title: "Processing Time",
            key: "handlingTimeDP",
            align: 'center',
            render: (text, record) => (record.handlingTimeDP) ? record.handlingTimeDP : EMPTY_TABLE_CELL,
        },
        reviewerId: {
            title: "Reviewer ID",
            key: "reviewer",
            render: (text, record) => record.reviewer ? record.reviewer.id : EMPTY_TABLE_CELL,
            sorter: () => undefined, // Since the sorting takes place in the backend.
            sortOrder: completionTableState.sorterInfo.columnKey === 'reviewer' && completionTableState.sorterInfo.order,
            sortDirections: ['ascend', 'descend'],
        },
        reviewerName: {
            title: "Reviewer",
            key: "rvFullName",
            render: (text, record) => {
                const firstName = record?.reviewer?.firstName
                const lastName = record?.reviewer?.lastName
                const fullName = `${firstName} ${lastName}`

                return record.reviewer ? fullName : EMPTY_TABLE_CELL
            }
        },
        reviewerEmail: {
            title: "Reviewer Email",
            key: "rvEmail",
            render: (text, record) => (record.reviewer && record.reviewer.email) ? record.reviewer.email : EMPTY_TABLE_CELL,
        },
        reviewHandleTime: {
            title: "Review Time",
            key: "handlingTimeRV",
            align: 'center',
            render: (text, record) => (record.handlingTimeRV) ? record.handlingTimeRV : EMPTY_TABLE_CELL,
        },
        inProgressOn: {
            title: "In Progress On",
            key: "inProgressOn",
            align: 'center',
            render: (text, record) => formatDate(record.inProgressOn, EMPTY_TABLE_CELL)
        },
        readyOn: {
            title: "Ready On",
            key: "readyOn",
            align: 'center',
            render: (text, record) => formatDate(record.readyOn, EMPTY_TABLE_CELL)
        },
        inReviewOn: {
            title: "In Review On",
            key: "inReviewOn",
            align: 'center',
            render: (text, record) => formatDate(record.inReviewOn, EMPTY_TABLE_CELL),
        },
        completedOn: {
            title: "Completed On",
            key: "completedOn",
            align: 'center',
            render: (text, record) => formatDate(record.completedOn, EMPTY_TABLE_CELL)
        },
        processedWithConsent: {
            title: "Processed With Consent",
            key: "processedWithConsent",
            align: 'center',
            render: (text, record) => record.processedWithConsent ? <CheckOutlined /> : EMPTY_TABLE_CELL
        },
        dataProcessingCost: {
            title: "Data Processing Cost",
            dataIndex: "dataProcessingCost",
            key: "dataProcessingCost",
            align: 'center',
            render: (text, record) => (record?.dataProcessingCost || record?.dataProcessingCost === 0) ? record?.dataProcessingCost : EMPTY_TABLE_CELL
        },
        dataProcessingCostApproved: {
            title: "Data Processing Cost Approval",
            key: "dataProcessingCostApproved",
            align: 'center',
            render: (_, record) => {
                return (
                    <Checkbox
                        defaultChecked={record?.dataProcessingCostApproved ?? false}
                        onChange={onApprove({ completionId: record.id, contributorRole: ROLE_DATA_PROCESSOR })}
                        disabled={COMPLETION_STATUS_VALUES[record?.status] !== 'Completed'} />
                )
            },
        },
        reviewCost: {
            title: "Review Cost",
            dataIndex: "reviewCost",
            key: "reviewCost",
            align: 'center',
            render: (text, record) => (record?.reviewCost || record?.reviewCost === 0) ? record?.reviewCost : EMPTY_TABLE_CELL
        },
        reviewCostApproved: {
            title: "Review Cost Approval",
            key: "reviewCostApproved",
            align: 'center',
            render: (_, record) => {
                return (
                    <Checkbox
                        defaultChecked={record?.reviewCostApproved ?? false}
                        onChange={onApprove({ completionId: record.id, contributorRole: ROLE_DATA_REVIEWER })}
                        disabled={COMPLETION_STATUS_VALUES[record?.status] !== 'Completed'} />
                )
            },
        },
    };
}

const factoryCompletionTableColumns = ({ completionTableState, onApprove }) => {

    const completionColumns = getCompletionTableColumnsConfig({ completionTableState, onApprove });
    return [
        completionColumns.completionId,
        completionColumns.taskId,
        completionColumns.metaData,
        completionColumns.status,
        completionColumns.dataprocessorId,
        completionColumns.dataprocessorName,
        completionColumns.dataprocessorEmail,
        completionColumns.dataprocessorHandleTime,
        completionColumns.reviewerId,
        completionColumns.reviewerName,
        completionColumns.reviewerEmail,
        completionColumns.reviewHandleTime,
        completionColumns.inProgressOn,
        completionColumns.readyOn,
        completionColumns.inReviewOn,
        completionColumns.completedOn,
        completionColumns.processedWithConsent,
        completionColumns.dataProcessingCost,
        completionColumns.dataProcessingCostApproved,
        completionColumns.reviewCost,
        completionColumns.reviewCostApproved,
    ].filter(Boolean)
}

const Completions = () => {

    const {
        selectedProject,
        setSelectedProject,
        //isLoading: projectLoading,
        isUploadingCsv,
        isUpdatingMetaData,
        errorTasksUpload,
        errorUpdateMetaData,
        uploadCsv,
        updateAudioLength,
        clearTasksUploadError,
        clearUpdateMetaDataError
    } = useContext(PMPrjSingleContext);



    const {
        isLoading: completionsLoading,
        error,

        completions,
        completionsCount,
        completionsCriteria,
        fetchProjectCompletions,

        downloadResults,
        isDownloadingResults,
        errorDownloadResults,
        clearDownloadResultsError,

        downloadStats,
        isDownloadingStats,
        errorDownloadStats,
        clearDownloadStatsError,

        downloadHistoricalStats,
        isDownloadingHistoricalStats,
        clearDownloadHistoricalStatsError,
        errorDownloadHistorcalStats,
    } = usePMCompletionsContext();

    const {
        initAppSocket
    } = useSocketContext();

    const { projectList } = useSelector(state => state.downloadCenter);

    const projectEntry = projectList[selectedProject.id]
    const archivesRetrievalError = projectEntry && projectEntry.error !== null

    const [state, setState] = useState({
        txtCompID: '',
        txtTaskID: '',
        filtersInfo: EMPTY_FILTERS_INFO,
        sorterInfo: {},
        selectedCompletionIDs: []
    })

    const [buttonFlags, setButtonFlags] = useState({
        uploadIsDisabled: false,
        exportIsDisabled: false,
    });

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = useCallback(() => {
        setIsModalOpen(true);
    }, []);

    const hideModal = useCallback(() => {
        setIsModalOpen(false);
    }, []);

		const [isGridView, setIsGridView] = useState(false);

		const switchToGridView = async () => {

			if (!isGridView) {
				const skip = 0;
				const limit = girdMaxRowCount;

				fetchProjectCompletions(selectedProject.id, {
            ...completionsCriteria,
            skip,
            limit,
        })
				setIsGridView(true);

			} else {
				await fetchProjectCompletions(selectedProject.id);
        setState(ps => ({
            ...ps,
            txtCompID: '',
            txtTaskID: '',
            filtersInfo: EMPTY_FILTERS_INFO,
            sorterInfo: {},
            selectedCompletionIDs: [],
        }))
				setIsGridView(false)
			}
		}

    const shouldDisableArchives = (string, words) => {
        let result = true
        words.forEach(word => {
            if (string.includes(word))
                result = false
        })
        return result
    }

    const dispatch = useDispatch();

    useEffect(() => {
        fetchProjectCompletions(selectedProject.id);
        //eslint-disable-next-line
    }, []);


    const handleUploadFile = (info) => {

        setButtonFlags(ps => ({ ...ps, exportIsDisabled: true }));
        const latestFileIndex = info.fileList.length - 1
        const tasksFile = info.fileList[latestFileIndex].originFileObj;
        const fileFormData = new FormData();
        fileFormData.append('tasks_file', tasksFile);

        uploadCsv(selectedProject.id, fileFormData, tasksFile.name)
            .then(() => {
                message.success('Tasks file is uploaded successfully.', 3);
                setButtonFlags(ps => ({ ...ps, exportIsDisabled: false }));
                // There is no need to refresh the completions table after upload as the file is uploaded asynchronously.
                // message.success('Tasks file is uploaded successfully.', 3);
                Modal.info({
                    title: 'Processing Tasks File Takes Time',
                    content: <div>
                        <p>Tasks file is uploaded successfully. However, it takes time to process the file.</p>
                        <p>Note: You will receive an email with the status when processing is done and if processing is successful, you will be able to see the completions in the completions table.</p>
                    </div>
                });

                return Promise.resolve();
            })
            .catch(() => {
                setButtonFlags(ps => ({ ...ps, exportIsDisabled: false }));
            });
    }

    const handleUpdateMetaData = () => {
        updateAudioLength(selectedProject.id)
            .then(() => {
                message.success('Completions meta-data is updated successfully');
                return Promise.resolve();
            })
            .then(() => fetchProjectCompletions(selectedProject.id))

            .catch(() => {
                //do nothing
            })
    }

    const handlePrepareArchivesForDownload = () => {
        // Testing whether the project is already started from before or not.
        if (!(selectedProject.id in projectList)) {
            // Then start creating the arhives and listenning to progress through socket.
            dispatch(initDowloadProjectFilesSession({ "projectID": selectedProject.id }));
            // Opening the app socket to receive updates for the new files.
            initAppSocket();
        }
        // Opening the download center modal for the user after clicking on the download button.
        dispatch(setShowDownloadCenterRequest(true));
    }

    const handleDownloadStats = () => {

        setButtonFlags(ps => ({ ...ps, uploadIsDisabled: true }));
        downloadStats()
            .then(file => {
                const url = window.URL.createObjectURL(file);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${selectedProject.id}_${snakeCase(selectedProject.name)}_STATS.csv`);
                link.click();
                setButtonFlags(ps => ({ ...ps, uploadIsDisabled: false }));
            })
            .catch(e => {
                setButtonFlags(ps => ({ ...ps, uploadIsDisabled: false }));
                //do nothing
            });
    }

    const handleDownloadHistoricalStats = () => {

        setButtonFlags(ps => ({ ...ps, uploadIsDisabled: true }));
        downloadHistoricalStats()
            .then(file => {
                const url = window.URL.createObjectURL(file);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${selectedProject.id}_${snakeCase(selectedProject.name)}_HISTOCAL_STATS.csv`);
                link.click();
                setButtonFlags(ps => ({ ...ps, uploadIsDisabled: false }));
            })
            .catch(e => {
                setButtonFlags(ps => ({ ...ps, uploadIsDisabled: false }));
                //do nothing
            });
    }

    const handleDownloadResults = () => {

        setButtonFlags(ps => ({ ...ps, uploadIsDisabled: true }));
        downloadResults()
            .then(file => {
                const url = window.URL.createObjectURL(file);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${selectedProject.id}_${snakeCase(selectedProject.name)}_RESULTS.json`);
                link.click();
                setButtonFlags(ps => ({ ...ps, uploadIsDisabled: false }));
            })
            .catch(e => {
                setButtonFlags(ps => ({ ...ps, uploadIsDisabled: false }));
                //do nothing
            });
    }


    const handleTableReset = () => {
        fetchProjectCompletions(selectedProject.id);
        setState(ps => ({
            ...ps,
            txtCompID: '',
            txtTaskID: '',
            filtersInfo: EMPTY_FILTERS_INFO,
            sorterInfo: {},
            selectedCompletionIDs: [],
        }))
    }
    const handleTableFiltersChange = (newFilters, newSorter) => {

        const filtersChanged = !isEqual(newFilters, state.filtersInfo);
        fetchProjectCompletions(selectedProject.id, {
            ...completionsCriteria,
            // Resetting the skip if the filters has changed to return the table to the first page.
            skip: filtersChanged ? 0 : completionsCriteria.skip,
            statuses: newFilters.status,
            orderColumn: newSorter.order ? newSorter.columnKey : null, // newSorter.order is used to know whether to the sort is applied or not as the columnKey is not updated when the order is removed.
            orderAscending: newSorter.order ? newSorter.order === 'ascend' : false,
        });

        setState(ps => ({
            ...ps,
            filtersInfo: newFilters,
            sorterInfo: newSorter,
        }));
    }

		const handleGridViewLoadMore = () => {

			const skip = completions.length;
			let limit = girdMaxRowCount;

			fetchProjectCompletions(selectedProject.id, {
				...completionsCriteria,
				skip,
				limit,
			}, true);
		}

    const refreshProjectData = useCallback((projectId) => {
        setSelectedProject(projectId)
    }, [setSelectedProject])

    // Refreshes the table data without changing the selected criteria.
    const handleTableRefreshData = () => {
        fetchProjectCompletions(selectedProject.id, completionsCriteria);
        refreshProjectData(selectedProject?.id);
    }
    const handleTablePaginationChange = (pageNumber, pageSize) => {
        const skip = (pageNumber - 1) * pageSize;
        const limit = pageSize;
        fetchProjectCompletions(selectedProject.id, {
            ...completionsCriteria,
            skip,
            limit,
        });
    }
    const handleTableRowSelection = (isSelected, row) => {
        setState(ps => ({
            ...ps,
            selectedCompletionIDs: isSelected ? [...state.selectedCompletionIDs, row.id] : state.selectedCompletionIDs.filter(suid => suid !== row.id),
        }))
    }

		const handleGridSelectionChange = (rows) => {
			setState(ps => ({
				...ps,
				selectedCompletionIDs: rows.map(r => r.id),
			}));
		};


    const handleTableSelectDeselectAll = (isSelected) => {
        const currentIDs = completions.map(c => c.id);
        setState(ps => ({
            ...ps,
            selectedCompletionIDs: isSelected ?

                // Adding the current ID's and removing any duplicates as individual rows could be selected before this action.
                [...new Set([...state.selectedCompletionIDs, ...currentIDs])] :

                // Removing the current completion ID's from the selected id's.
                state.selectedCompletionIDs.filter(cid => !currentIDs.includes(cid)),

        }));
    }


    const handleCompletionIDChange = e => {
        e.persist();
        setState(ps => ({ ...ps, txtCompID: e.target.value }));
    }
    const handleCompletionIDSearch = searchTerm => {

        // Making sure the search input has changed before sending the request to the backend.
        if (completionsCriteria.completionID === searchTerm) return;

        if (!searchTerm) {
            setState(ps => ({ ...ps, txtTaskID: "" }));
        }

        fetchProjectCompletions(selectedProject.id, {
            ...completionsCriteria,
            skip: 0, // Resetting the skip as there is a new search is applied.
            completionID: searchTerm,
        })
    }

    const handleTaskIDChange = e => {
        e.persist();
        setState(ps => ({ ...ps, txtTaskID: e.target.value }));
    }
    const handleTaskIDSearch = searchTerm => {

        // Making sure the search input has changed before sending the request to the backend.
        if (completionsCriteria.taskID === searchTerm) return;

        if (!searchTerm) {
            setState(ps => ({ ...ps, txtTaskID: "" }));
        }


        fetchProjectCompletions(selectedProject.id, {
            ...completionsCriteria,
            skip: 0, // Resetting the skip as there is a new search is applied.
            taskID: searchTerm,
        })
    }

    const handleDataProcessorFilterChange = (dps) => {
        dps = dps.map(v => v.trim()).filter(v => v !== '')
        fetchProjectCompletions(selectedProject.id, {
            ...completionsCriteria,
            skip: 0, // Resetting the skip as there is a new search is applied.
            dataProcessors: dps,
        })
    }
    const handleReviewersFilterChange = (rvs) => {
        rvs = rvs.map(v => v.trim()).filter(v => v !== '')
        fetchProjectCompletions(selectedProject.id, {
            ...completionsCriteria,
            skip: 0, // Resetting the skip as there is a new search is applied.
            reviewers: rvs,
        })
    }

    const handleUnselectAllCompletions = () => setState(ps => ({ ...ps, selectedCompletionIDs: [] }))

    const completionsCriteriaIsApplied = useCallback(() => {
        const filtersApplied = !isEqual(state.filtersInfo, EMPTY_FILTERS_INFO) || !isEqual(state.sorterInfo, {}) || state.txtCompID || state.txtTaskID;
        return filtersApplied;
    }, [state.filtersInfo, state.sorterInfo, state.txtTaskID, state.txtCompID])

    const exportButtons = (
        <Menu>
            <Menu.Item>
                {/* Download Stats of the completions */}
                <Button
                    type='link'
                    size='small'
                    onClick={handleDownloadStats}
                    icon={<DownloadOutlined />}
                >
                    Stats
                </Button>
            </Menu.Item>

            <Menu.Item>
                {/**Export Historical Stats */}
                <Button
                    type='link'
                    size='small'
                    onClick={handleDownloadHistoricalStats}
                    icon={<DownloadOutlined />}
                >
                    Historical Stats
                </Button>
            </Menu.Item>

            <Menu.Item>
                {/* Download Results */}
                <Button
                    type='link'
                    size='small'
                    onClick={handleDownloadResults}
                    icon={<DownloadOutlined />}
                >
                    Results
                </Button>
            </Menu.Item>
        </Menu>
    )

    const { mutateAsync: mutateApprove, data: approveData, error: approveError, isError } = useUnitAndCostApprove({ projectId: selectedProject?.id });

    const hasFailedIds = !isEmpty(approveData?.data?.failed);
    const failedIds = approveData?.data?.failed;

    const getHandleOnApprove = useCallback(
        ({ completionId, contributorRole }) =>
            (evt) => {
                mutateApprove({
                    projectId: selectedProject?.id,
                    role: contributorRole,
                    requestBody: {
                        "ids": [completionId],
                        "approved": evt.target.checked
                    }
                })
                showModal()
            }
        , [selectedProject?.id, mutateApprove, showModal]);

    const completionTableColumns = useMemo(() => {
        return factoryCompletionTableColumns({
            completionTableState: state,
            onApprove: getHandleOnApprove
        })
    }, [ getHandleOnApprove, state]);

    return (
			<Fragment>
				{/* Download results error modal */}
				<ErrorModal
					error={errorDownloadResults}
					onDone={clearDownloadResultsError}
				/>

				{/* Download stats error modal */}
				<ErrorModal
					error={errorDownloadStats}
					onDone={clearDownloadStatsError}
				/>

				{/* Download historical stats error modal */}
				<ErrorModal
					error={errorDownloadHistorcalStats}
					onDone={clearDownloadHistoricalStatsError}
				/>

				{/* Upload tasks error modal */}
				<ErrorModal error={errorTasksUpload} onDone={clearTasksUploadError} />

				{/* Update Meta-Data error modal */}
				<ErrorModal
					error={errorUpdateMetaData}
					onDone={clearUpdateMetaDataError}
				/>

				{isError && (
					<ErrorModal
						error={formatError(approveError, 'Failed To Update Approve Value!')}
						visible={isModalOpen}
						onDone={hideModal}
					/>
				)}

				<InfoModal
					onDone={hideModal}
					show={hasFailedIds && isModalOpen}
					title={'Failed To Update Completion(s)!'}
					message={
						<div>
							<p>
								Failed to update the following completion(s) as they are not
								completed yet.
							</p>
							{failedIds?.map((f) => (
								<p key={f}>{`ID: ${f}`}</p>
							))}
						</div>
					}
				/>

				{/* Search And Filters Collapse */}
				{!error && (
					<Collapse
						expandIcon={({ isActive }) => (
							<CaretRightOutlined
								rotate={isActive ? 90 : 0}
								style={{ fontSize: '1.2rem' }}
							/>
						)}
						ghost
					>
						<Panel header={<ManualAssignmentHeader />} key="2">
							<CompletionUpdate
								selectedCompletionIDs={state.selectedCompletionIDs}
								refreshTable={handleTableRefreshData}
							/>
						</Panel>

						<Panel header={<SearchHeader />} key="1">
							<Row style={rowStyle}>
								<Col span={labelColSpan}>
									<FieldLabel text="Completion ID" />
								</Col>
								<Col span={fieldColSpan}>
									<Input.Search
										enterButton
										allowClear
										placeholder="Search completions by ID (numbers only)..."
										type="number"
										value={state.txtCompID}
										onChange={handleCompletionIDChange}
										onSearch={handleCompletionIDSearch}
										onBlur={() => handleCompletionIDSearch(state.txtCompID)}
									/>
								</Col>
							</Row>

							<Row style={rowStyle}>
								<Col span={labelColSpan}>
									<FieldLabel text="Task ID" />
								</Col>
								<Col span={fieldColSpan}>
									<Input.Search
										enterButton
										allowClear
										placeholder="Search completions by task ID (numbers only)..."
										type="number"
										value={state.txtTaskID}
										onChange={handleTaskIDChange}
										onSearch={handleTaskIDSearch}
										onBlur={() => handleTaskIDSearch(state.txtTaskID)}
									/>
								</Col>
							</Row>

							<Row style={rowStyle}>
								<Col span={labelColSpan}>
									<FieldLabel text="Data Processor" />
								</Col>
								<Col span={fieldColSpan}>
									<TagsBox
										style={{ width: '100%' }}
										value={completionsCriteria.dataProcessors}
										onChange={handleDataProcessorFilterChange}
										placeholder="Search completions by data processor's email, name, or id"
										allowClear={true}
									/>
								</Col>
							</Row>

							<Row style={rowStyle}>
								<Col span={labelColSpan}>
									<FieldLabel text="Reviewer" />
								</Col>
								<Col span={fieldColSpan}>
									<TagsBox
										style={{ width: '100%' }}
										value={completionsCriteria.reviewers}
										onChange={handleReviewersFilterChange}
										placeholder="Search completions by reviewer's email, name, or id"
										allowClear={true}
									/>
								</Col>
							</Row>
						</Panel>
					</Collapse>
				)}

				<Divider />

				<Space style={{ display: 'flex', justifyContent: 'space-between' }}>
					{/**Unselect Button */}
					{!isGridView && (
						<Button
							size="small"
							onClick={handleUnselectAllCompletions}
							disabled={state.selectedCompletionIDs.length === 0}
						>
							Unselect
						</Button>
					)}

					{isGridView && (
						<span style={{ fontSize: '0.7rem' }}>
							Selected: {state.selectedCompletionIDs.length}{' '}
						</span>
					)}
					<Space>
						<Space>
							<span style={{ fontSize: '0.7rem' }}>Table</span>
							<Switch
								checkedChildren={<CheckOutlined />}
								defaultChecked={isGridView}
								onChange={switchToGridView}
								disabled={completionsLoading}
							/>
							<span style={{ fontSize: '0.7rem' }}>Grid</span>
						</Space>

						{/* Upload Tasks */}
						<Upload
							beforeUpload={() => false} //Required to stop antd default post request
							onChange={handleUploadFile}
							showUploadList={false}
						>
							<Button
								type="primary"
								size="small"
								disabled={buttonFlags.uploadIsDisabled}
								loading={isUploadingCsv}
							>
								<UploadOutlined /> Upload Tasks
							</Button>
						</Upload>

						{/* Download Buttons */}
						<Dropdown
							overlay={exportButtons}
							disabled={buttonFlags.exportIsDisabled}
						>
							<Button
								type="primary"
								ghost
								size="small"
								icon={<DownOutlined />}
								loading={
									isDownloadingResults ||
									isDownloadingHistoricalStats ||
									isDownloadingStats
								}
							>
								Export
							</Button>
						</Dropdown>

						{/* Initiate Archives */}
						<Button
							type="dashed"
							size="small"
							disabled={
								completions.length === 0 ||
								shouldDisableArchives(selectedProject.xmlConfig, SPECIAL_TAGS)
							}
							onClick={handlePrepareArchivesForDownload}
							icon={
								projectEntry &&
								projectEntry.status !==
									DOWNLOAD_AUDIO_FILES_PRJECT_STATUS.Done &&
								!archivesRetrievalError ? (
									<LoadingOutlined />
								) : (
									<RocketOutlined />
								)
							}
						>
							Prepare Project Files
						</Button>

						{/* Update Meta-Data */}
						<Button
							type="dashed"
							size="small"
							disabled={completions.length === 0 ? true : false}
							onClick={handleUpdateMetaData}
							loading={isUpdatingMetaData}
						>
							<SyncOutlined /> Update Meta-Data
						</Button>

						{/** Refresh Table Data */}
						<RefreshTableButton
							tooltipText="Refresh Table Data"
							onRefresh={handleTableRefreshData}
							isDisabled={completionsLoading}
						/>

						{/* Reset Table */}
						<ResetTableButton
							tooltipText="Clear Selection, Filters, Sorting, and Search"
							tooltipPlacement="topLeft"
							// isDisabled={completionsLoading || (!completionsCriteriaIsApplied() && !state.selectedCompletionIDs.length)}
							onReset={handleTableReset}
						/>
					</Space>
				</Space>

				{/* Contributors error and table */}
				<ErrorPage error={error} />
				{!error && isGridView && (
					<Space direction="vertical" style={{ width: '100%', height: '100%' }}>
						<GridView
							rowData={completions}
							onSelection={handleGridSelectionChange}
							isLoading={completionsLoading}
							onLoadMore={handleGridViewLoadMore}
							totalRowCount={completionsCount}
							maxCount={girdMaxRowCount}
						/>
					</Space>
				)}
				{!error && !isGridView && (
					<Space direction="vertical" style={{ width: '100%' }}>
						<Table
							scroll={{ x: 'max-content' }}
							className="completions-table ant-table"
							size="middle"
							dataSource={completions.map((item) => ({
								...item,
								key: item.id,
							}))}
							columns={completionTableColumns}
							loading={completionsLoading}
							pagination={false}
							onChange={(pagination, filters, sorter) =>
								handleTableFiltersChange(filters, sorter)
							}
							rowSelection={{
								selectedRowKeys: state.selectedCompletionIDs,
								onSelect: (row, isSelected) =>
									handleTableRowSelection(isSelected, row),
								onSelectAll: (isSelected) =>
									handleTableSelectDeselectAll(isSelected),
								hideDefaultSelections: true,
							}}
						/>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								marginTop: '20px',
							}}
						>
							<Pagination
								showSizeChanger
								showQuickJumper
								showTotal={() => `Total: ${completionsCount}`}
								total={completionsCount}
								pageSizeOptions={['10', '20', '50', '100']}
								pageSize={completionsCriteria.limit}
								current={
									completionsCriteria.skip / completionsCriteria.limit + 1
								}
								onChange={handleTablePaginationChange}
							/>
						</div>
					</Space>
				)}
			</Fragment>
		);
}

export default Completions;
