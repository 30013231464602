import * as yup from 'yup';

const genderSchema = yup.string().oneOf(['male', 'female', 'other']);

export const locationSchema = yup.object().shape({
    city: yup.string().nullable(true),
    state: yup.string().nullable(true),
    country: yup.string().required(),
    formatted: yup.string().required(),
    lat: yup.number().required(),
    lon: yup.number().required()
});

export const nativeLangSchema = yup.object().shape({
    id: yup.number().required(),
    name: yup.string().required(),
});

export const educationLevelSchema = yup.object().shape({
    id: yup.number().required(),
    name: yup.string().required(),
});

export const skillsSchema = yup.array().of(yup.object().shape({
    id: yup.number().required(),
    name: yup.string().required(),
})).min(1);

export const otherLanguagesSchema = yup.array().of(yup.object().shape({
    id: yup.number().required(),
    name: yup.string().required(),
}));

export const contributorProfileSchema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().required(),
    gender: genderSchema.nullable(true),
    dateOfBirth:  yup.date().nullable(true),
    originLocation:  locationSchema.nullable(true) ,
    currentLocation: locationSchema,
    skills: skillsSchema,
    otherLanguages: otherLanguagesSchema,
    nativeLanguage: nativeLangSchema,
    education:  educationLevelSchema
});


export const isContributorProfileComplete = (ctProfile) => contributorProfileSchema.isValidSync(ctProfile);