import { Alert } from 'antd';

const alertTypes = {
	5: {
		type: 'success',
		message: <h3>Prediction completed successfully!</h3>,
		description: (
			<p>
				Your prediction was processed successfully. You can review the results
				now.
			</p>
		),
	},
	4: {
		type: 'error',
		message: <h3>Prediction failed!</h3>,
		description: (
			<p>
				There was an issue processing your prediction. Please check your email
				for details.
			</p>
		),
	},
	default: {
		type: 'info',
		message: <h3>Prediction in progress...</h3>,
		description: (
			<p>
				The prediction process may take some time. Please be patient while we
				complete the task.
			</p>
		),
	},
};

function PredictionResultAlert({ statusCode }) {
	const alertContent = alertTypes[statusCode] || alertTypes.default;

	return (
		<Alert
			type={alertContent.type}
			message={alertContent.message}
			description={alertContent.description}
			showIcon
			closable
		/>
	);
}

export { PredictionResultAlert };
