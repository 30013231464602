import React from 'react';
import { Button, Menu, Dropdown, Tooltip } from 'antd';
import { CONTRIBUTORS_ASSIGNMENT_TYPE } from '../../../../contexts/PMPrjSingleContext';
import '../../../Styles/Button.scss'

const AssignmentActionsButton = ({ tooltipText, text, onClickHandler, disabled, type, icon, className }) => {
    const keys = Object.keys(CONTRIBUTORS_ASSIGNMENT_TYPE);

    return (
        <Tooltip title={tooltipText}>
            <Dropdown
                disabled={disabled}
                overlay={
                    <Menu onClick={onClickHandler}>
                        <Menu.Item key={keys[0]}>Data Processor</Menu.Item>
                        <Menu.Item key={keys[1]}>Reviewer</Menu.Item>
                         <Menu.Item key={keys[2]}>
                            Ad-Hoc
                        </Menu.Item>
                    </Menu>
                }>
                <Button
                    className={className}
                    size='small'
                    type={type}
                    icon={icon}>
                    {text}
                </Button>

            </Dropdown>
        </Tooltip>
    )
}

export default AssignmentActionsButton;