import React, { Fragment, useState, useMemo, useEffect, useContext, useCallback } from 'react';
import { Button, Table, Space, Row, Col, Input, Select, message, Modal, Pagination, Tooltip } from "antd";
import { RedoOutlined, LinkOutlined } from '@ant-design/icons';
import { nanoid } from 'nanoid';
import isEqual from 'lodash.isequal';

import FieldLabel from '../../Common/FieldLabel';
import ErrorPage from '../../Error/ErrorPage';
import ErrorModal from '../../Error/ErrorModal';
import InfoModal from '../../Common/InfoModal';

import { formatDate } from '../../Common/Utils';
import usePMUsersContext from '../../../contexts/PMUsersContext';
import { usePMProjectSingleContext } from '../../../contexts/PMPrjSingleContext';
import useOptionsContext from '../../../contexts/OptionsContext';
import PMPrjContext from '../../../contexts/PMPrjContext';
import { TrueIcon, FalseIcon } from '../../Common/CustomIcons';
import { renderTagsTableCell } from '../../Common/TagsTableCell';

import './Users.scss';
import '../../Styles/Button.scss'
import RefreshTableButton from '../../Common/RefreshTableButton';
import ResetTableButton from '../../Common/ResetTableButton';
import { EMPTY_TABLE_CELL } from '../../../Constants';
import { CustomLink } from '../../Common/CustomLink';

const LABEL_SPAN = 3;
const FIELD_SPAN = 11;
const CONTROL_SPAN = 10;

const fieldStyle = { margin: '20px 0 20px 0' };

// The following is used to match the initial state of filters info to the initial state of the table filters info.
const EMPTY_FILTERS_INFO = {
    isActive: null,
    role: null,
    otherLanguages: null,
    nativeLanguage: null,
    skills: null,
}

const ContributorRole = {
    0: "Data Processors",
    1: "Reviewers",
    2: "Ad-Hoc Contributors"
}

const CT_LABEL = 'contributor';
const PM_LABEL = 'pm'

const UsersTable = () => {

    const {
        projects,
        isLoading: projectsLoading,
        error: projectsError,
        getProjects,
        clearError
    } = useContext(PMPrjContext);

    const {
        users,
        usersCount,
        criteria: userCriteria,
        isLoading,
        error,
        fetchUsers,

        actvStateUpdateError,
        setUsersActiveState,
        clearActvStateUpdateError,
    } = usePMUsersContext();

    const {
        contributorsLoading: assigningMembersLoading,
        contributorsAssignmentError,
        setContributorsAssignedState,
        clearContributorAssignmentError,
    } = usePMProjectSingleContext();

    const {
        isLoading: areOptionsLoading,

        skills,
        skillsError,
        fetchSkillOptions,
        clearSkillsError,

        languages,
        languagesError,
        fetchLanguageOptions,
        clearLanguagesError,
    } = useOptionsContext();

    const [state, setState] = useState({
        selectedUserIDs: [],
        selectedProjectID: null,
        filtersInfo: EMPTY_FILTERS_INFO,
        sorterInfo: {},
        transactionError: null,
    })

    const [userSearchValue, setUserSearchValue] = useState("");

    useEffect(() => {
        fetchLanguageOptions();
        fetchSkillOptions();
        fetchUsers();
        getProjects();

        //eslint-disable-next-line
    }, [])

    const allLanguages = useMemo(() =>
        languages.map(ln => ({ text: ln.name, value: ln.id }))
        , [languages])

    const createFailedAssignmentError = (failedAssignments) => {
        return {
            title: "Failed To Assign Some Selected Users!",
            message: (
                <Fragment>
                    <h4>The following users were not assigned</h4>
                    {failedAssignments.map(fa =>
                        <div key={nanoid()} style={{ margin: '15px 0 15px 0' }}>
                            <Row><Col span={4} style={{ fontWeight: 'bold' }}>User ID:</Col><Col>{fa.id}</Col></Row>
                            <Row><Col span={4} style={{ fontWeight: 'bold' }}>Role:</Col><Col>{fa.role}</Col></Row>
                            <Row><Col span={4} style={{ fontWeight: 'bold' }}>Error:</Col><Col>{fa.message}</Col></Row>
                        </div>
                    )}
                </Fragment>
            )
        }
    }

    const createFailedToChangeActStateError = (failedIDs, state) => {
        return {
            title: `Failed To ${state ? 'Activate' : 'Deactivate'} Some Selected Users!`,
            message: `Failed to ${state ? 'activate' : 'deactivate'} user ID's: ${failedIDs.join(', ')} as they are already ${state ? 'active' : 'inactive'}.`
        }
    }

    const isUsersCriteriaApplied = () => {
        const filtersApplied = !isEqual(state.filtersInfo, EMPTY_FILTERS_INFO) || !isEqual(state.sorterInfo, {});
        return filtersApplied;
    }

    const handleOnAssign = useCallback((assignmentType) => {

        // Making sure a projec is selected.
        if (state.selectedProjectID === null) {
            message.warning('Project is not selected!', 3);
            return;
        }

        // Checking the selected inactive users.
        const inactiveUsers = users.filter(user => state.selectedUserIDs.includes(user.id) && user.isActive !== true);
        if (inactiveUsers.length > 0) {
            Modal.warning({
                title: "Can't assign de-activated users.",
                content: <p>The following users are inactive:
                    <br /><ul>{inactiveUsers.map(user => <li> {user.email} </li>)}</ul>
                    Please deselect the inactive user emails and try again.</p>,
            });
            return;
        }

        // Assigning members after making sure all required conditions are met.
        setContributorsAssignedState(state.selectedUserIDs, true, assignmentType, state.selectedProjectID)
            .then(failedAssignments => {
                if (failedAssignments) {
                    setState(ps => ({
                        ...ps,
                        transactionError: createFailedAssignmentError(failedAssignments)
                    }))
                }
                else {
                    message.success(`Selected members are assigned successfully as ${ContributorRole[assignmentType]}.`, 3)
                }
            })
            .catch(e => {
                //Do nothing
            });
    }, [setContributorsAssignedState, state.selectedProjectID, state.selectedUserIDs, users])

    const handleActivate = (activate) => {
        setUsersActiveState(state.selectedUserIDs, activate)
            .then(failedActvStateUpdates => {
                if (failedActvStateUpdates) {
                    setState(ps => ({
                        ...ps,
                        transactionError: createFailedToChangeActStateError(failedActvStateUpdates, activate)
                    }))
                }
                else {
                    message.success(`Contributor(s) have been ${activate ? 'activated' : 'deactivated'} successfully.`)
                }
            })
            .catch(e => {
                //Do nothing. Error is reported from the context by the error modal.
            })
    }

    const handleSearchChange = useCallback((e) => {
        setUserSearchValue(e.target.value)
    }, [])

    const handleOnSearchUserCommitted = useCallback((searchTerm) => {

        // Skipping the search if the term hasn't changed.
        if (userCriteria.search === searchTerm) return;

        fetchUsers({
            ...userCriteria,
            skip: 0,
            search: searchTerm,
        });
    }, [userCriteria, fetchUsers])

    const handleTableFiltersChange = (newFilters, newSorter) => {

        const filtersChanged = !isEqual(newFilters, state.filtersInfo);

        fetchUsers({
            ...userCriteria,
            skip: filtersChanged ? 0 : userCriteria.skip,
            role: Array.isArray(newFilters.role) && newFilters.role.length > 0 ? newFilters.role[0] : null,
            isActive: Array.isArray(newFilters.isActive) && newFilters.isActive.length > 0 ? newFilters.isActive[0] : null,
            otherLanguages: newFilters.otherLanguages,
            nativeLanguage: newFilters.nativeLanguage,
            skills: newFilters.skills,
            orderColumn: newSorter.order ? newSorter.columnKey : null, // newSorter.order is used to know whether to the sort is applied or not as the columnKey is not updated when the order is removed.
            orderAscending: newSorter.order ? newSorter.order === 'ascend' : true,
        });

        // Updating the table filter object.
        setState(ps => ({
            ...ps,
            filtersInfo: newFilters,
            sorterInfo: newSorter,
        }));
    }

    const handleTablePaginationChange = (pageNumber, pageSize) => {
        let newSkip = (pageNumber - 1) * pageSize;
        let newLimit = pageSize;
        // Fetching user with the new skip and limit.
        fetchUsers({
            ...userCriteria,
            skip: newSkip,
            limit: newLimit,
        });
    }

    const handleTableRowSelection = (row, isSelected) => {
        setState(ps => ({
            ...ps,
            selectedUserIDs: isSelected ? [...state.selectedUserIDs, row.id] : state.selectedUserIDs.filter(suid => suid !== row.id),
        }));
    }

    const handleTableRowClick = (row) => {
        if (state.selectedUserIDs.includes(row.id)) {
            setState(ps => ({
                ...ps,
                selectedUserIDs: state.selectedUserIDs.filter(suid => suid !== row.id)
            }))
        } else {
            setState(ps => ({
                ...ps,
                selectedUserIDs: [...state.selectedUserIDs, row.id]
            }))
        }
    }

    const isTableRecordSelectable = (record) => Boolean(record.role === CT_LABEL)

    const handleTableSelectDeselectAll = (isSelected) => {
        const currentCTids = users.filter(u => isTableRecordSelectable(u)).map(u => u.id);
        setState(ps => ({
            ...ps,
            selectedUserIDs: isSelected ?

                // Adding the current user ID's and removing any duplicates as individual rows could be selected before this action.
                [...new Set([...state.selectedUserIDs, ...currentCTids])] :

                // Removing the current user ID's from the selected id's.
                state.selectedUserIDs.filter(uid => !currentCTids.includes(uid)),
        }));
    }

    const handleResetTable = useCallback(() => {
        setState(ps => ({
            ...ps,
            selectedUserIDs: [],
            filtersInfo: EMPTY_FILTERS_INFO,
            sorterInfo: {},
        }))
        fetchUsers();
    }, [fetchUsers])

    const handleRefreshData = useCallback(() => {
        fetchUsers({ ...userCriteria });
    }, [fetchUsers, userCriteria])

    const handleResetProjects = () => getProjects();

    const handleProjectSearchTxtChanged = (searchValue) => getProjects({ search: searchValue });


    //eslint-disable-next-line
    const columns = useMemo(() => [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            sorter: () => undefined, // Since the sorting takes place in the backend.
            sortOrder: state.sorterInfo.columnKey === 'id' && state.sorterInfo.order,
            sortDirections: ['ascend', 'descend'],
            render: (id, record) => {
                return record.role === CT_LABEL ? <CustomLink to={`app/contribute/profile/${id}`}>{id} <LinkOutlined /></CustomLink> : id
            },
        },
        {
            title: "First Name",
            dataIndex: "firstName",
            key: "firstName",
            sorter: () => undefined, // Since the sorting takes place in the backend.
            sortOrder: state.sorterInfo.columnKey === 'firstName' && state.sorterInfo.order,
            sortDirections: ['ascend', 'descend'],
            render: (firstName) => firstName || EMPTY_TABLE_CELL
        },
        {
            title: "Last Name",
            dataIndex: "lastName",
            key: "lastName",
            sorter: () => undefined, // Since the sorting takes place in the backend.
            sortOrder: state.sorterInfo.columnKey === 'lastName' && state.sorterInfo.order,
            sortDirections: ['ascend', 'descend'],
            render: (lastName) => lastName || EMPTY_TABLE_CELL
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            sorter: () => undefined, // Since the sorting takes place in the backend.
            sortOrder: state.sorterInfo.columnKey === 'email' && state.sorterInfo.order,
            sortDirections: ['ascend', 'descend']
        },
        {
            title: "Role",
            key: "role",
            dataIndex: "role",
            render: (role) => ((role === PM_LABEL) ? 'Project Manager' : 'Contributor'),
            filters: [
                {
                    text: 'Project Manager',
                    value: PM_LABEL,
                },
                {
                    text: 'Contributor',
                    value: CT_LABEL,
                }
            ],
            filteredValue: state.filtersInfo.role || null,
            filterMultiple: false,
            sorter: () => undefined, // Since the sorting takes place in the backend.
            sortOrder: state.sorterInfo.columnKey === 'role' && state.sorterInfo.order,
            sortDirections: ['ascend', 'descend']
        },
        {
            title: "Registered On",
            dataIndex: "registeredOn",
            key: "registeredOn",
            render: registeredOn => formatDate(registeredOn),
            sorter: () => undefined, // Since the sorting takes place in the backend.
            sortOrder: state.sorterInfo.columnKey === 'registeredOn' && state.sorterInfo.order,
            sortDirections: ['ascend', 'descend']
        },
        {
            title: "Active",
            dataIndex: "isActive",
            key: "isActive",
            filters: [
                {
                    text: 'Active',
                    value: true,
                },
                {
                    text: 'Inactive',
                    value: false,
                }
            ],

            filteredValue: state.filtersInfo.isActive || null,
            filterMultiple: false,
            render: isActive => isActive ? <TrueIcon /> : <FalseIcon />,
            sorter: () => undefined, // Since the sorting takes place in the backend.
            sortOrder: state.sorterInfo.columnKey === 'isActive' && state.sorterInfo.order,
            sortDirections: ['ascend', 'descend']
        },
        {
            title: "Skills",
            key: "skills",
            dataIndex: 'skills',
            render: renderTagsTableCell,
            filters: skills.map(skill => ({ text: skill.name, value: skill.id })),
            filteredValue: state.filtersInfo.skills || null,
            filterMultiple: true,
            filterSearch: true,
        },
        {
            title: "Other Languages",
            key: "otherLanguages",
            dataIndex: 'otherLanguages',
            render: renderTagsTableCell,
            filters: allLanguages,
            filteredValue: state.filtersInfo.otherLanguages || null,
            filterMultiple: true,
            filterSearch: true,
        },
        {
            title: "Native Language",
            key: "nativeLanguage",
            dataIndex: 'nativeLanguage',
            render: renderTagsTableCell,
            filters: allLanguages,
            filteredValue: state.filtersInfo.nativeLanguage || null,
            filterMultiple: true,
            filterSearch: true,
        }
    ]);

    return (
        <Fragment>

            <ErrorModal error={projectsError} onDone={clearError} />
            <ErrorModal error={contributorsAssignmentError} onDone={clearContributorAssignmentError} />
            <ErrorModal error={actvStateUpdateError} onDone={clearActvStateUpdateError} />
            <ErrorModal error={languagesError} onDone={clearLanguagesError} />
            <ErrorModal error={skillsError} onDone={clearSkillsError} />

            <InfoModal
                show={state.transactionError}
                title={state.transactionError ? state.transactionError.title : ''}
                message={state.transactionError ? state.transactionError.message : ''}
                onDone={() => setState(ps => ({ ...ps, transactionError: null }))}
            />

            {/* Assignment Controls */}
            <Row style={fieldStyle} gutter={5} justify="end">
                <Col span={LABEL_SPAN}>
                    <FieldLabel text='Assign To Project:' />
                </Col>
                <Col span={FIELD_SPAN}>
                    <Select
                        showSearch
                        allowClear
                        defaultActiveFirstOption={false}
                        filterOption={false}
                        size='small'
                        style={{ width: '100%' }}
                        placeholder="Search Projects..."
                        loading={projectsLoading}
                        value={state.selectedProjectID}
                        onSelect={v => setState(ps => ({ ...ps, selectedProjectID: v }))}
                        onClear={() => setState(ps => ({ ...ps, selectedProjectID: null }))}
                        onSearch={handleProjectSearchTxtChanged}
                        options={projects.map(prj => ({ value: prj.id, key: prj.id, label: prj.internalName }))}
                    />
                </Col>
                <Col span={CONTROL_SPAN}>
                    <Space style={{ display: "flex", justifyContent: 'flex-end' }}>
                        <Button
                            type='primary'
                            size='small'
                            disabled={state.selectedUserIDs.length === 0 || assigningMembersLoading}
                            text='Assign As Data Processors'
                            onClick={() => handleOnAssign(0)}>
                            Assign As Data Processors
                        </Button>
                        <Button
                            type='primary'
                            size='small'
                            className='btn-success'
                            disabled={state.selectedUserIDs.length === 0 || assigningMembersLoading}
                            onClick={() => handleOnAssign(1)}>
                            Assign As Reviewers
                        </Button>
                        <Button
                            type='primary'
                            size='small'
                            className='btn-secondary'
                            disabled={state.selectedUserIDs.length === 0 || assigningMembersLoading}
                            onClick={() => handleOnAssign(2)}
                        >
                            Assign As Ad-Hoc
                        </Button>
                        <Tooltip title="Refresh Projects">
                            <Button
                                type='dashed'
                                size='small'
                                disabled={projectsLoading}
                                onClick={handleResetProjects}>
                                <RedoOutlined />
                            </Button>
                        </Tooltip>
                    </Space>
                </Col>
            </Row>


            <Row style={fieldStyle} gutter={5}>
                <Col span={LABEL_SPAN}>
                    <FieldLabel text='Find Users:' />
                </Col>
                <Col span={FIELD_SPAN}>
                    <Input.Search
                        placeholder="Search by ID, Name, or Email..."
                        allowClear
                        enterButton
                        size='small'
                        style={{ width: '100%' }}
                        value={userSearchValue}
                        onChange={handleSearchChange}
                        onSearch={handleOnSearchUserCommitted}
                        onBlur={() => handleOnSearchUserCommitted(userSearchValue)}
                    />
                </Col>
                <Col span={CONTROL_SPAN}>
                    <Space style={{ display: "flex", justifyContent: 'flex-end' }}>
                        <Button
                            className='btn-success'
                            size='small'
                            disabled={isLoading || state.selectedUserIDs.length === 0}
                            onClick={() => handleActivate(true)}>
                            Activate
                        </Button>
                        <Button
                            type='danger'
                            size='small'
                            disabled={isLoading || state.selectedUserIDs.length === 0}
                            onClick={() => handleActivate(false)}>
                            Deactivate
                        </Button>
                    </Space>
                </Col>
            </Row>

            {/* refresh and reset buttons */}
            <Space style={{ width: '100%', justifyContent: 'end', marginTop: '10px' }}>
                <RefreshTableButton
                    tooltipText="Refresh Table Data"
                    isDisabled={isLoading}
                    onRefresh={handleRefreshData}
                />
                <ResetTableButton
                    tooltipText="Clear Selection, Filters, Sorting, and Search"
                    tooltipPlacement="topLeft"
                    isDisabled={isLoading || (!isUsersCriteriaApplied() && !state.selectedUserIDs.length)}
                    onReset={handleResetTable}
                />
            </Space>

            <ErrorPage error={error} />
            {!error &&
                <Space direction='vertical' style={{ width: '100%' }}>
                    <Table
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: () => isTableRecordSelectable(record) && handleTableRowClick(record),
                            };
                        }}
                        scroll={{ x: true }}
                        dataSource={users ? users.map(item => ({ ...item, key: item.id })) : []}
                        columns={columns}
                        loading={isLoading || areOptionsLoading || assigningMembersLoading}
                        onChange={(pagination, filters, sorter) => handleTableFiltersChange(filters, sorter)}
                        pagination={false}
                        rowSelection={{
                            selectedRowKeys: state.selectedUserIDs,
                            onSelect: (row, isSelected) => handleTableRowSelection(row, isSelected),
                            onSelectAll: (isSelected) => handleTableSelectDeselectAll(isSelected),
                            getCheckboxProps: record => ({
                                disabled: !isTableRecordSelectable(record), // Column configuration not to be checked
                            }),
                        }}
                    />
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <Pagination
                            showSizeChanger
                            showQuickJumper
                            showTotal={() => `Total: ${usersCount}`}
                            total={usersCount}
                            pageSize={userCriteria.limit}
                            current={(userCriteria.skip / userCriteria.limit) + 1}
                            onChange={handleTablePaginationChange}
                        />
                    </div>
                </Space>
            }

        </Fragment>
    )
}

export default UsersTable;
