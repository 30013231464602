import React, { Fragment, useEffect, useMemo, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Tabs, Tooltip, Divider, Spin } from 'antd';
import {
	TeamOutlined,
	BarsOutlined,
	CarryOutOutlined,
	FileTextOutlined,
	SettingOutlined,
} from '@ant-design/icons';
import { SiHandshake } from 'react-icons/si';
import { MdQueryStats } from 'react-icons/md';
import { BsCurrencyDollar } from 'react-icons/bs';
import { FaRobot } from 'react-icons/fa';

import TabHeader from './TabHeader';
import ErrorPage from '../../Error/ErrorPage';
import ErrorBoundary from '../../Error/ErrorBoundary';

import ProjectInfo from './ProjectInfo/ProjectInfo';
import ProjectHeader from './ProjectHeader';
import Guidelines from './Guidelines/Guidelines';
import TasksXmlConfig from './TasksXmlConfig/TasksXmlConfig';
import ContributorsTable from './Contributors/ContributorsTable';
import Completions from './Completions/Completions';
import ConsentAgreement from './ConsetnAgreement/ConsentAgreement';
import Adhoc from './Adhoc/Adhoc';
import Cost from './Cost/Cost';
import { AiAssisted } from './AiAssisted/AiAssisted';

import { usePMProjectSingleContext } from '../../../contexts/PMPrjSingleContext';
import { PMCompletionsContextProvider } from '../../../contexts/PMCompletionsContext';
import { SocketContextProvider } from '../../../contexts/SocketContext';

const { TabPane } = Tabs;

const projectTabs = {
	projectDetails: {
		key: 'basic',
		title: 'Details',
		safe: true,
		icon: <BarsOutlined />,
		dispComp: <ProjectInfo />,
	},
	guidelines: {
		key: 'guidelines',
		title: 'Guidelines',
		safe: true,
		icon: <FileTextOutlined />,
		dispComp: <Guidelines />,
	},
	consentAgreement: {
		key: 'agreement',
		title: 'Consent Agreement',
		safe: true,
		icon: <SiHandshake />,
		dispComp: <ConsentAgreement />,
	},
	xmlConfiguration: {
		key: 'xmlConfig',
		title: 'XML Configuration',
		safe: false,
		icon: <SettingOutlined />,
		dispComp: <TasksXmlConfig />,
	},
	projectContirubtors: {
		key: 'contributors',
		title: 'Contributors',
		safe: false,
		icon: <TeamOutlined />,
		dispComp: <ContributorsTable />,
	},
	projectCompletions: {
		key: 'completions',
		title: 'Completions',
		safe: false,
		icon: <CarryOutOutlined />,
		dispComp: (
			<SocketContextProvider>
				<PMCompletionsContextProvider>
					<Completions />
				</PMCompletionsContextProvider>
			</SocketContextProvider>
		),
	},
	projectAdhocs: {
		key: 'adhoc',
		title: 'Ad-hoc',
		safe: false,
		icon: <MdQueryStats size={'1.3rem'} />,
		dispComp: <Adhoc />,
	},
	projectCost: {
		key: 'cost',
		title: 'Cost',
		safe: false,
		icon: <BsCurrencyDollar size={'1.3rem'} />,
		dispComp: <Cost />,
	},
	projectAIAssisted: {
		key: 'ai',
		tooltip: 'AI Assisted',
		title: 'Generate Predictions',
		safe: false,
		icon: <FaRobot size={'1.3rem'} />,
		dispComp: <AiAssisted />,
	},
};

const factoryProjectTabs = (ft) => {
	return [
		projectTabs.projectDetails,
		projectTabs.guidelines,
		projectTabs.consentAgreement,
		projectTabs.xmlConfiguration,
		projectTabs.projectContirubtors,
		projectTabs.projectCompletions,
		projectTabs.projectAdhocs,
		projectTabs.projectCost,
		projectTabs.projectAIAssisted,
	].filter(Boolean);
};

const DisplayProject = () => {

	const {
		selectedProject,
		isLoading,
		error,
		setSelectedProject,
		clearAllErrors,
	} = usePMProjectSingleContext();

	const { project_id } = useParams();
	const history = useHistory();

	useEffect(() => {
		// Loading the project which is clicked by the user.
		setSelectedProject(project_id);

		// Clear all error that may have been reported when the page unloads.
		return () => {
			clearAllErrors();
		};
		//eslint-disable-next-line
	}, []);

	const tabs = useMemo(() => {
		return factoryProjectTabs();
	}, []);

	const hanldeOnTabChange = useCallback(
		(tabKey) => {
			if (tabKey === 'basic') {
				setSelectedProject(project_id);
			}
		},
		[project_id, setSelectedProject],
	);

	return (
		<ErrorBoundary>
			<Spin spinning={isLoading}>
				{/* Displaying the error message if there is any.*/}
				<ErrorPage
					error={error}
					redirect={{
						text: 'Back To Projects',
						action: () => history.goBack(),
					}}
				/>

				{/* The tabs control */}
				{!error && selectedProject && (
					<Fragment>
						<ProjectHeader project={selectedProject} />

						<Divider style={{ marginTop: '0px' }} />

						<Tabs
							defaultActiveKey="basic"
							size="large"
							tabPosition="left"
							style={{ paddingBottom: '150px' }}
							onChange={hanldeOnTabChange}
						>
							{tabs?.map((tabData) => {
								return (
									<TabPane
										key={tabData.key}
										tab={
											<Tooltip
												placement="rightTop"
												title={tabData.tooltip ?? tabData.title}
											>
												{tabData.icon}
											</Tooltip>
										}
									>
										<TabHeader value={tabData.title} />
										{tabData.safe ? (
											tabData.dispComp
										) : (
											<ErrorBoundary>{tabData.dispComp}</ErrorBoundary>
										)}
									</TabPane>
								);
							})}
						</Tabs>
					</Fragment>
				)}
			</Spin>
		</ErrorBoundary>
	);
};

export default DisplayProject;
